export const CHAT_MESSAGES_ACTIONS = {
  // CHAT MESSAGES ACTIONS
  SET_MESSAGE_LIST: "SET_MESSAGE_LIST",
  PUSH_TOKEN_TO_MESSAGE_LIST: "PUSH_TOKEN_TO_MESSAGE_LIST",
  ADD_MESSAGE: "ADD_MESSAGE",
  ADD_MESSAGE_TO_SYNC_QUEUE: "ADD_MESSAGE_TO_SYNC_QUEUE",
  REMOVE_MESSAGE_FROM_SYNC_QUEUE_BY_ID: "REMOVE_MESSAGE_FROM_SYNC_QUEUE_BY_ID",
  SET_STREAMING_MESSAGE: "SET_STREAMING_MESSAGE",
  SET_SYNC_STREAM_MESSAGE: "SET_SYNC_STREAM_MESSAGE",
  SET_INITIAL_MESSAGE_LIST_FETCH_COMPLETED:
    "SET_INITIAL_MESSAGE_LIST_FETCH_COMPLETED",
  RESET_CHAT_MESSAGES: "RESET_CHAT_MESSAGES",
};
