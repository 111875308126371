import { ResponsiveContainer } from "recharts";
import { Box } from "@mui/material";

const RechartCreator = ({ height = "320px", width = "100%", children }) => {
  return (
    <Box style={{ height, width }}>
      <ResponsiveContainer>{children}</ResponsiveContainer>
    </Box>
  );
};

export default RechartCreator;
