import {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { MatxLoading } from "app/components";
import { firebaseConfig } from "config.js";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  EmailAuthProvider,
  currentUser,
  linkWithCredential,
} from "firebase/auth";
import useSettings from "app/hooks/useSettings";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axiosInstance from "app/utils/axiosConfig";
import useMainAuth from "app/hooks/useMainAuth";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const initialState = {
  isInitialised: false, // while false, shows loading spinner screen
  isAuthenticated: false, // While true, both Firebase & Node Server are authenticated
  firebase: {
    method: "FIREBASE",
    user: null,
    isAuthenticated: false,
    triggerServerVerifyFirebaseToken: false,
    triggerServerLogout: false,
    firebaseAccessToken: null,
  },
  nodeServer: {
    method: "NODEJS",
    isInitialised: false,
    isAuthenticated: false,
    serverUser: null,
    tokens: null,
    updating: false,
    verifying: false,
    isAdmin: false,
  },
};

const reducer = (state, action) => {
  // console.log(">>> reducer | action -> ", action);
  // console.log(">>> reducer | state -> ", state);
  switch (action.type) {
    /* COMMON ACTIONS */
    case "RESET": {
      return { ...initialState, isInitialised: true };
    }
    case "CLEAR": {
      return { ...initialState, isInitialised: false };
    }

    /* FIREBASE ACTIONS  */
    case "FB_AUTH_STATE_CHANGED": {
      const { isAuthenticated, user, tokens } = action.payload;
      return {
        ...state,
        isInitialised: true,
        firebase: {
          ...state.firebase,
          isAuthenticated: isAuthenticated,
          user,
          firebaseAccessToken: tokens,
        },
      };
    }

    /* NODE SERVER ACTIONS  */
    case "SERVER_AUTH_STATE_CHANGED": {
      const { isAuthenticated, serverUser, tokens } = action.payload;
      const isAdmin = serverUser?.role === "admin" ? true : false;
      const newState = {
        ...state,
        nodeServer: {
          ...state.nodeServer,
          isAuthenticated,
          isInitialised: true,
          serverUser,
          isAdmin,
          tokens,
        },
      };
      return newState;
    }

    case "UPDATE_USER_TOKENS": {
      const { trigger } = action.payload;
      return { ...state, updating: trigger };
    }

    case "VERIFY_USER": {
      const { trigger } = action.payload;
      return { ...state, verifying: trigger };
    }

    case "SERVER_AUTH_NEW_TOKENS": {
      const { tokens } = action.payload;
      return {
        ...state,
        nodeServer: {
          ...state.nodeServer,
          tokens,
        },
      };
    }

    case "MAIN_AUTH_UPDATE": {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated,
      };
    }

    default: {
      return state;
    }
  }
};

const MainAuthContext = createContext({
  ...initialState,
  firebaseMethods: {
    createUserWithEmail: (email, password) => Promise.resolve(),
    signInWithEmail: (email, password) => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    logout: () => Promise.resolve(),
  },
  nodeServerMethods: {
    verifyWithBackend: () => Promise.resolve(),
    logoutServer: () => Promise.resolve(),
    clear: () => Promise.resolve(),
    updateUserTokenOnServer: () => Promise.resolve(),
  },
});

export const MainAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { settings, updateSettings } = useSettings();
  const intervalRef = useRef(null); // Reference to hold the interval
  const refreshTokensIntervalRef = useRef(null);

  /* COMMON METHODS */
  const clear = () => {
    dispatch({
      type: "CLEAR",
    });
  }; // DONE

  useEffect(() => {
    if (state.firebase.isAuthenticated && state.nodeServer.isAuthenticated) {
      if (!state.isAuthenticated) {
        dispatch({
          type: "MAIN_AUTH_UPDATE",
          payload: {
            isAuthenticated: true,
          },
        });
      }

      return;
    }

    if (state.isAuthenticated) {
      if (
        !state.firebase.isAuthenticated ||
        !state.nodeServer.isAuthenticated
      ) {
        dispatch({
          type: "MAIN_AUTH_UPDATE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    }
  }, [
    state.isAuthenticated,
    state.firebase.isAuthenticated,
    state.nodeServer.isAuthenticated,
  ]); // Done

  const logoutServer = useCallback(async () => {
    try {
      console.log(">>> logoutServer | event");
      const refreshToken = state.tokens?.refresh?.token;
      if (!refreshToken) return false;

      const response = await axiosInstance({
        url: `/v1/auth/logout/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          refreshToken: JSON.stringify(refreshToken),
        },
      });
      const data = await response.json();
      console.log(">>> logoutServer | data -> ", data);

      dispatch({
        type: "CLEAR",
      });

      return data;
    } catch (error) {
      console.error("Error logout from server:", error);
      return false;
    }
  }, [state.tokens?.refresh?.token]);

  const logout = useCallback(() => {
    console.log(">>> Main logout process");
    toast.info("Logging out... ");

    signOut(auth); // Logout from Firebase
    logoutServer(); // Logout from Node Server

    dispatch({
      type: "CLEAR",
    });

    window.location.reload();
  }, [logoutServer]); // DONE

  /* FIREBASE METHODS */
  const signInWithEmail = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  }; // DONE

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }; // DONE

  const createUserWithEmail = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  }; // DONE

  const logoutFirebase = () => {
    signOut(auth); // Logout from Firebase
  }; // DONE

  const verifyWithBackend = useCallback(
    async (idToken) => {
      // console.log("verifyWithBackend STARTING");
      try {
        await axiosInstance({
          url: `v1/auth/verify-firebase-token`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
        }).then((response) => {
          /* console.log(
            ">>> verifyWithBackend | response.data -> ",
            response.data
          ); */
          if (response.data.isAuthenticated) {
            /* console.log(
              "Disaptching SERVER_AUTH_STATE_CHANGED | isAuthenticated -> true"
            ); */
            // console.log("SETTING TO: ", response.data.tokens.access.token);
            axiosInstance.defaults.headers.common[
              "AuthorizationServer"
            ] = `Bearer ${response.data.tokens.access.token}`;
            dispatch({
              type: "SERVER_AUTH_STATE_CHANGED",
              payload: {
                isAuthenticated: true,
                serverUser: response.data.user,
                tokens: response.data.tokens,
              },
            });
          } else {
            throw new Error("Disaptching RESET | isAuthenticated -> false");
          }

          return response.data;
        });
      } catch (error) {
        console.error("Error verifying token with backend:", error);
        console.log("LOGGING OUT, REFRESH TOKEN IS NO LONGER VALID");
        logout();
        return false;
      }
    },
    [logout]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // console.log(">>> onAuthStateChanged | user updated to -> ", user);
      if (user) {
        const tokens = await user.getIdToken();
        if (!tokens) {
          if (!state.isInitialised) {
            dispatch({
              type: "FB_AUTH_STATE_CHANGED",
              payload: { isAuthenticated: false, tokens: null, user: null },
            });
          }
          toast.error("Error getting Firebase token");
          return;
        }

        dispatch({
          type: "FB_AUTH_STATE_CHANGED",
          payload: {
            isAuthenticated: true,
            tokens,
            user: {
              ...user,
              id: user.uid,
              email: user.email,
              avatar: user.photoURL,
              name: user.displayName || user.email,
            },
          },
        });

        /* console.log(
          "SENDING NODE SERVER SIGNIN WITH ACCESS TOKEN | ACCESS TOKEN: ",
          user?.accessToken
        ); */
        if (!user.accessToken) {
          console.error(
            ">>> FirebaseAuthContext | useEffect | onAuthStateChanged | Error getting Firebase token"
          );
          toast.error("Firebase authentification error #5");
          logout();
          return;
        }
        verifyWithBackend(user?.accessToken);
      } else {
        if (!state.isInitialised) {
          console.log(
            ">>> onAuthStateChanged | user is not found! Logging out!"
          );
          dispatch({
            type: "FB_AUTH_STATE_CHANGED",
            payload: { isAuthenticated: false, tokens: null, user: null },
          });
        }
      }
    });

    return () => unsubscribe();
  }, [dispatch, logout, state.isInitialised, verifyWithBackend]); // DONE

  // IM PRETTY SURE THIS IS REDUNDANT !!!
  /* useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const tokens = await user.getIdToken();
        if (!tokens) {
          console.error(
            ">>> FirebaseAuthContext | useEffect | onIdTokenChanged | Error getting Firebase token"
          );
          toast.error("Firebase authentification error #3");
          
          logout();
          return;
        }

        Cookies.set("tokens", tokens);

        //dispatch({
        //  type: "TRIGGER_SERVER_VERIFY_TOKEN",
        //  payload: { trigger: true },
        //});
		console.log("DON'T FORGET TO TRIGGER VERIFY TOKEN HERE")


		dispatch({
			type: "FB_AUTH_STATE_CHANGED",
			payload: {
			  isAuthenticated: true,
			  tokens,
			  user: {
				...user,
				id: user.uid,
				email: user.email,
				avatar: user.photoURL,
				name: user.displayName || user.email,
			  },
			},
		  });
      }
    });

    return () => unsubscribe();
  }, []);  */ // MISSING TODO: TRIGGER A RE-VERIFY TOKEN ON NODE SERVER

  const checkTokenExpiration = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      // Force refresh the token
      const tokens = await user.getIdToken(true);
      if (!tokens) {
        console.error(
          ">>> checkTokenExpiration | Error getting Firebase token"
        );
        toast.error("Firebase authentification error #2");

        logout();
        return;
      }
      Cookies.set("tokens", tokens);

      dispatch({
        type: "FB_AUTH_STATE_CHANGED",
        payload: {
          isAuthenticated: true,
          tokens,
          user: {
            ...user,
            id: user.uid,
            email: user.email,
            avatar: user.photoURL,
            name: user.displayName || user.email,
          },
        },
      });
      /* console.log(
        "SENDING NODE SERVER SIGNIN WITH ACCESS TOKEN | ACCESS TOKEN: ",
        user?.accessToken
      ); */
      if (!user.accessToken) {
        console.error(
          ">>> FirebaseAuthContext | useEffect | onAuthStateChanged | Error getting Firebase token"
        );
        toast.error("Firebase authentification error #5");

        logout();
        return;
      }
      verifyWithBackend(user?.accessToken);

      /* dispatch({
        type: "TRIGGER_SERVER_VERIFY_TOKEN",
        payload: { trigger: true },
      }); */
      console.log("DON'T FORGET TO TRIGGER VERIFY TOKEN HERE");
    }
  }, [logout, verifyWithBackend]); // DONE

  useEffect(() => {
    // If there's no existing interval, set one
    if (!intervalRef.current) {
      // Set an interval to check every 50 minutes (3000000 milliseconds)
      intervalRef.current = setInterval(checkTokenExpiration, 3000000); // 50 Minutes ( DEFAULT, as per Firebase docs, the token expires after 1 hour )
      // intervalRef.current = setInterval(checkTokenExpiration, 60000); // 1 minute ( DEBUGGING )
      // intervalRef.current = setInterval(checkTokenExpiration, 30000); // 30 Seconds ( DEBUGGING )
    }

    // Cleanup on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null; // Clear the reference to ensure no stale intervals
      }
    };
  }, [checkTokenExpiration]); // DONE

  /*useEffect(() => {
    console.log("state: ");
    console.log(state);

    console.log("state.triggerServerVerifyFirebaseToken: ");
    console.log(state.triggerServerVerifyFirebaseToken);
  }, [state]); */

  /* NODE SERVER METHODS */

  const addToExistingUserEmailAndPasswordCredentials = useCallback(
    async (desiredPassword) => {
      if (!desiredPassword) {
        toast.error("Password cannot be empty");
        return false;
      }

      console.log(
        "addToExistingUserEmailAndPasswordCredentials | desiredPassword -> ",
        desiredPassword
      );
      if (!auth.currentUser) {
        console.error(
          "addToExistingUserEmailAndPasswordCredentials | Error getting Firebase user"
        );
        return false;
      }
      // Create an email/password credential
      const credential = EmailAuthProvider.credential(
        state.firebase.user.email,
        desiredPassword
      );

      // Link the credential to the current user
      linkWithCredential(auth.currentUser, credential)
        .then((result) => {
          console.log("Account linking success", result);
          console.log("Password set successfully!");
          toast.success("Password set successfully!");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error setting password:", error);
          toast.error("Error setting password. " + error.message);
        });
    },
    [state.firebase.user]
  );

  /* const updateUserTokenOnServer = useCallback(async () => {
    try {
      const refreshToken = state.nodeServer.tokens?.refresh?.token;
      if (
        !state.isInitialised ||
        !state.firebase.isAuthenticated ||
        !refreshToken
      ) {
        if (refreshTokensIntervalRef.current) {
          clearInterval(refreshTokensIntervalRef.current);
        }
        return false;
      }

      const config = {
        url: `/v1/auth/refresh-server-tokens`,
        method: "post",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${refreshToken}`,
        },
        data: JSON.stringify({
          refreshToken: refreshToken,
        }),
      };

      // console.log("config:");
      // console.log(config);

      const response = await axiosInstance.request(config);
      // const data = await response.json();
      console.log(
        ">>> updateUserTokenOnServer | response.data -> ",
        response.data
      );

      const newAccessToken = response?.data?.access?.token || null;
      const newRefreshToken = response?.data.refresh?.token || null;

      console.log(
        ">>> updateUserTokenOnServer (refresh-tokens) | newAccessToken -> ",
        newAccessToken
      );
      console.log(
        ">>> updateUserTokenOnServer (refresh-tokens) | newRefreshToken -> ",
        newRefreshToken
      );

      if (!newAccessToken || !newRefreshToken) {
        throw new Error("Disaptching RESET | isAuthenticated -> false");
      }
      const newAuthToken = `Bearer ${newRefreshToken}`;
      if (!newAuthToken) {
        throw new Error("Disaptching RESET | isAuthenticated -> false");
      }
      console.log(
        ">>> updateUserTokenOnServer (refresh-tokens) | newAuthToken -> ",
        newAuthToken
      );

      axiosInstance.defaults.headers.common[
        "AuthorizationServer"
      ] = `Bearer ${newAccessToken}`;
      dispatch({
        type: "SERVER_AUTH_NEW_TOKENS",
        payload: {
          tokens: {
            access: {
              token: newAccessToken,
            },
            refresh: {
              token: newRefreshToken,
            },
          },
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error updating token with backend:", error);
      toast.error("Firebase authentification error #4");

      logout();
      return false;
    }
  }, [
    state.nodeServer.isAuthenticated,
    state.nodeServer.isInitialised,
    state.nodeServer.tokens?.refresh?.token,
  ]); */

  /* useEffect(() => {
    // This is AXIOS triggering via the Settings context, when any request to the server is 401!
    console.log("settings.triggers.server - ");
    console.log(settings.triggers.server);
    if (settings.triggers.server.triggerRefreshToken) {
      // If triggered externally, then trigger the local updateUserTokenOnServer
      dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: true },
      });

      // Disable external Trigger
      updateSettings({
        flags: {
          ...settings.flags,
          server: {
            ...settings.flags.server,
            triggerRefreshToken: true,
            refreshResult: null,
          },
        },
        showGlobalAlert: {
          show: true,
          message: "Refreshing tokens... Page will reload in 3 seconds.",
          severity: "info",
        },
      });

      return;
    }

    if (!settings.triggers.server.triggerRefreshToken && state.updating) {
      // Wait for external trigger to come down while internal trigger is still active, than run updateUserTokenOnServer and disable internal trigger
      updateUserTokenOnServer();
      dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: true },
      });
      return;
    }

    return () => {
      dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: false },
      });
    };
  }, [settings.triggers.server]); */

  /* useEffect(() => {
    console.log(">>> useEffect | state.isInitialised -> ", state.isInitialised);
    console.log(
      ">>> useEffect | state.nodeServer.isAuthenticated -> ",
      state.nodeServer.isAuthenticated
    );
    console.log(
      ">>> useEffect | state.firebase.isAuthenticated -> ",
      state.firebase.isAuthenticated
    );

    if (
      !state.isInitialised || // UI is not initialised
      !state.nodeServer.isAuthenticated || // Server is not authenticated
      !state.firebase.isAuthenticated // Firebase not authenticated
    ) {
      console.log(">>> useEffect | clearInterval!!!");
      if (refreshTokensIntervalRef.current) {
        // if interval is set, clear it
        clearInterval(refreshTokensIntervalRef.current);
      }
      return;
    }

    console.log(">>> useEffect | setInterval!!!");
    // Set up the interval and store its ID in the useRef
    refreshTokensIntervalRef.current = setInterval(() => {
      console.log("UPDATE USER TOKENS ON SERVER - every 5 minutes");
      updateUserTokenOnServer();
      // }, 5 * 60 * 1000); // 5 minutes in milliseconds
    }, 20 * 1000); // 5 minutes in milliseconds
    // TODO: TEMP DISABLED, I want to see if this is necessary or does the firebase based trigger work ( verify-firebase-token API also refreshes the tokens)

    // Cleanup: clear the interval when the component is unmounted
    return () => {
      console.log(">>> useEffect | (return / unmount) clearInterval!!!");
      if (refreshTokensIntervalRef.current) {
        clearInterval(refreshTokensIntervalRef.current);
      }
    };
  }, [
    updateUserTokenOnServer,
    state.nodeServer.isInitialised,
    state.nodeServer.isAuthenticated,
  ]); */
  // Empty dependency array ensures this useEffect runs once when component mounts

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <MainAuthContext.Provider
      value={{
        ...state,
        logout,
        firebaseMethods: {
          logoutFirebase,
          signInWithGoogle,
          signInWithEmail,
          createUserWithEmail,
          firebaseAuth: auth,
          addToExistingUserEmailAndPasswordCredentials,
        },
        nodeServerMethods: {
          verifyWithBackend,
          logoutServer,
          clear,
          // updateUserTokenOnServer,
        },
      }}
    >
      {children}
    </MainAuthContext.Provider>
  );
};

export default MainAuthContext;
