import { useCallback, useEffect, useRef, useContext } from "react";
import { getMessagesFromList } from "app/contexts/chat/utils/Utils.js";
import { toast } from "react-toastify";
import {
  generateChatName,
  setChatName,
  getChatById,
  getChatMessagesByChatId,
} from "app/services/ChatService";
import { MainChatContext } from "app/contexts/chat/MainChatContext";

import { useRefs } from "app/contexts/RefsContext";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";
import { useChatRoomCallBacks } from "app/contexts/chat/callbacks/ChatRoomCallBacks";

export const useChatRoomEffects = (state, dispatch, globalState) => {
  const { updateCurrentChatRoom, getCompletedMessagesLength } =
    useChatRoomCallBacks(globalState, dispatch);

  const { resetTimeoutRef } = useRefs();

  useEffect(() => {
    if (state.clearTimeouts) {
      clearTimeout(resetTimeoutRef.current);
      dispatch({ type: "SET_CLEAR_TIMEOUTS", payload: false });
    }
  }, [dispatch, resetTimeoutRef, state.clearTimeouts]);

  // Used to undo the triggerScrollToBottom after 250ms
  useEffect(() => {
    if (state.triggerScrollToBottom) {
      // && !resetTimeoutRef.current) {
      // Set a timeout to reset to false after 250ms
      resetTimeoutRef.current = setTimeout(() => {
        dispatch({ type: "SET_TRIGGER_SCROLL", payload: false });
      }, 250);
    }

    return () => {
      clearTimeout(resetTimeoutRef.current);
    };
  }, [dispatch, resetTimeoutRef, state.triggerScrollToBottom]);

  /**
   * Clears the resetTimeoutRef timeout when the component unmounts
   * Or when the ACTION_TYPES.RESET_ALL was dispatched
   */
  useEffect(() => {
    if (resetTimeoutRef.current && !state.currentChatRoom) {
      clearInterval(resetTimeoutRef.current);
    }
  }, [resetTimeoutRef, state.currentChatRoom]);

  /**
   * Generate chat room name when at least 2 messages are available
   */
  useEffect(() => {
    if (globalState?.chatInitialization?.chatInitialized) {
      if (
        state.currentChatRoom.chatId !== null &&
        !state.currentChatRoom.chatName &&
        !globalState.chatMessages.streamingMessage &&
        !globalState.chatMessages.syncLastMsgToServerTrigger
      ) {
        // console.log("VVV currentChatRoom: ", state.currentChatRoom);
        // chat room is initialized
        if (!state.currentChatRoom.chatName) {
          // chat room does not have a name

          if (
            globalState.chatMessages.messageList &&
            getCompletedMessagesLength() >= 2 &&
            globalState.chatThreads.generateChatRoom?.state === "init" &&
            !globalState.chatMessages.streamingMessage
          ) {
            /* console.log(
              "VVV messageList has at least 2 messages, and generateChatRoom is not in progress"
            ); */

            // Trigger - chat room has at exactly 2+ messages and finished streaming
            // console.log("VVV !!!!!!!!!!!!!!!!!!! Generating chat room name...");
            dispatch({
              type: "GENERATE_CHAT_NAME_UPDATE_STATE",
              payload: "in_progress",
            });
          }

          if (
            globalState.chatThreads.generateChatRoom?.state === "in_progress"
          ) {
            // console.log("VVV Generating chat room name...");
            generateChatName(
              getMessagesFromList(globalState.chatMessages.messageList)
            ).then((data) => {
              try {
                if (data.status === 200) {
                  const jsonPayload = JSON.parse(data.data);
                  const { chatName, meta_description } = jsonPayload;
                  if (chatName && meta_description) {
                    dispatch({
                      type: "GENERATE_CHAT_NAME",
                      payload: {
                        chatName,
                        meta_description,
                        state: "setting_name",
                      },
                    });
                  } else {
                    throw new Error("Name or meta_description is missing");
                  }
                } else {
                  throw new Error("Error generating chat name");
                }
              } catch (e) {
                console.error("VVV generateChatName | error:", e);
                dispatch({
                  type: "GENERATE_CHAT_NAME_UPDATE_STATE",
                  payload: "error",
                });
              }
            });
          }

          if (
            globalState.chatThreads.generateChatRoom?.state === "setting_name"
          ) {
            try {
              // console.log("VVV Setting chat room name...");
              const { chatName, meta_description } =
                globalState.chatThreads.generateChatRoom;
              const chatId = state.currentChatRoom.chatId;
              if (!chatName) {
                throw new Error("can't set chat name without a name");
              }

              if (!chatId) {
                throw new Error("can't set chat name without a chatId");
              }

              setChatName({ chatId, chatName, meta_description })
                .then((data) => {
                  //console.log("VVV setChatName | data:", data);
                  if (data.status === 200) {
                    dispatch({
                      type: "GENERATE_CHAT_NAME",
                      payload: { chatName, meta_description, state: "done" },
                    });
                  } else {
                    throw new Error("Error setting chat name");
                  }
                })
                .catch((e) => {
                  console.error("VVV setChatName | error:", e);
                  dispatch({
                    type: "GENERATE_CHAT_NAME_UPDATE_STATE",
                    payload: "error",
                  });
                });
            } catch (e) {
              console.error("VVV setChatName | error:", e);
              dispatch({
                type: "GENERATE_CHAT_NAME_UPDATE_STATE",
                payload: "error",
              });
            }
          }

          if (
            globalState.chatThreads.generateChatRoom?.state === "done" &&
            !state.currentChatRoom.chatName
          ) {
            // console.log("VVV Chat room name is not set, updating...");
            updateCurrentChatRoom();
          }
        }

        if (
          globalState.chatThreads.generateChatRoom?.state === "done" &&
          state.currentChatRoom.chatName
        ) {
          // console.log("VVV Chat room name is set, updating...");
          dispatch({
            type: "GENERATE_CHAT_NAME_UPDATE_STATE",
            payload: "completed",
          });
        }
      }
    }
  }, [
    state.currentChatRoom,
    state.currentChatRoom.state,
    globalState.chatMessages.messageList,
    globalState.chatThreads.generateChatRoom,
    dispatch,
    updateCurrentChatRoom,
    globalState.chatRoom.currentChatRoom.chatId,
    globalState.chatMessages.syncLastMsgToServerTrigger,
    getCompletedMessagesLength,
    globalState.chatMessages.streamingMessage,
    globalState?.chatInitialization?.chatInitialized,
  ]);

  /**
   * Update currentChatRoom Triggered by dispatched action
   * THIS **EXECUTES** THE LOADING OF CONTENT IN THE CHAT ROOM
   */
  useEffect(() => {
    if (globalState?.chatInitialization?.chatInitialized) {
      /* console.log("updateCurrentChatRoom | state:", state);
      console.log(
        "updateCurrentChatRoom | state.triggerUpdateCurrentChatRoom:",
        state.triggerUpdateCurrentChatRoom
      ); */
      if (state.triggerUpdateCurrentChatRoom) {
        if (state.triggerUpdateCurrentChatRoomByChatId) {
          const chatId = state.triggerUpdateCurrentChatRoomByChatId;
          /* console.log(
            "THIS SHOULD RUN ONLY ONCE PER CHAT ROOM | Update to chatId: ",
            chatId
          ); */
          updateCurrentChatRoom({
            chatId,
          });
          dispatch({
            type: "UPDATE_CURRENT_CHAT_ROOM",
            payload: { action: false, chatId: null },
          });
          return;
        } else {
          /* console.log(
            "THIS SHOULD RUN ONLY ONCE PER CHAT ROOM | Update without a chatId. "
          ); */
          updateCurrentChatRoom();
          return;
        }
      }
    }
  }, [
    dispatch,
    globalState?.chatInitialization?.chatInitialized,
    state,
    state.currentChatRoom,
    state.intialMessageListFetchCompleted,
    state.triggerUpdateCurrentChatRoom,
    state.triggerUpdateCurrentChatRoomByChatId,
    updateCurrentChatRoom,
  ]);
};
