import { useCallback } from "react";
import { toast } from "react-toastify";

import {
  sendChat, // Used for non-streaming messages.
  sendStreamChat,
  getChatMessagesByChatId,
  pushNewMessageToChatHistoryByChatId,
} from "app/services/ChatService";

import { v4 as uuidv4 } from "uuid";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";

export const useChatMessagesCallBacks = (globalState, dispatch) => {
  /**
   * Get message body for messageList
   */
  const getMessageBodyForServerSync = ({ message, actor, actorId, type }) => {
    // console.log("PPP | getMessageBody | message:", message);
    // console.log("PPP | getMessageBody | actor:", actor);

    let MessageFromType = null;
    if (type === "nerdMessage") {
      MessageFromType = "Nerd";
    }
    if (type === "userMessage") {
      MessageFromType = "User";
    }

    if (!MessageFromType) {
      /* console.error(
        "PPP | getMessageBodyForServerSync | MessageFromType is not available!"
      ); */
      return;
    }

    // When last message was a token expect {message: "", type: "token"} as message. Extract the message from it.
    // When last message was a User, expect message: "" as message.
    const extractedMessage = message?.message || message || null;

    if (!extractedMessage) {
      /* console.error(
        "PPP | getMessageBodyForServerSync | message is not available!"
      ); */
      return;
    }

    return {
      message: extractedMessage,
      messageType: "text",
      MessageFromType,
      MessageFrom: actorId,
    };
  }; //

  /**
   * Add message to sync queue
   */
  const addMessageToSyncQueue = useCallback(
    (message) => {
      console.log("addMessageToSyncQueue | message:", message);
      const frontEndUniqueMessageId = uuidv4();
      message.frontEndUniqueMessageId = frontEndUniqueMessageId;
      dispatch({ type: "ADD_MESSAGE_TO_SYNC_QUEUE", payload: message });
    },
    [dispatch]
  ); //

  /**
   * Update Message List
   */
  const updateMessageList = useCallback(
    (message, type) => {
      console.log("PPP | updateMessageList | message:", message);
      console.log("PPP | updateMessageList | type:", type);

      // get current user
      let actor = null;
      let actorId = null;
      if (type === "userMessage") {
        // console.log("PPP | (type === 'userMessage')");
        actorId =
          globalState.chatParticipants?.currentUser?.serverUser?.id || null;
        actor = globalState.chatParticipants?.currentUser?.serverUser || null;
        if (!actor || !actorId) {
          console.error(
            "PPP | updateMessageList | actor of type Human is not available!"
          );
          return;
        }
      }
      if (type === "nerdMessage") {
        // console.log("PPP | (type === 'nerdMessage')");

        const oneOnOneWithNerdId = globalState.chatRoom.oneOnOneWithNerdId;

        // TODO: Need opponent to start a chat.
        // Replace this string dependancy later with FINDING THE RIGHT NERD SPEAKER BASED ON CHAT RESPONSE!
        /* console.warn(
          "PPP | TEMP FIX. USING OPPONENT USER DOES NOT SUPPORT MULI-NERD CHAT!"
        ); */

        /* console.log(
          "PPP | (type === 'nerdMessage') updateMessageList | oneOnOneWithNerdId:",
          oneOnOneWithNerdId
        ); */

        // console.log("PPP | globalState: ", globalState);

        // Get Nerd details
        /* console.log(
          "PPP | globalState.chatParticipants.allNerdsList: ",
          globalState.chatParticipants.allNerdsList
        ); */
        const nerdDetails = globalState.chatParticipants.allNerdsList.find(
          (nerd) => nerd.nerdId === oneOnOneWithNerdId
        );

        // console.log("PPP | updateMessageList | nerdDetails: ", nerdDetails);

        actorId = nerdDetails?.nerdId || null;
        // console.log("PPP | updateMessageList | actorId: ", actorId);

        actor = nerdDetails || null;
        // console.log("PPP | updateMessageList | actor: ", actor);

        if (!actor || !actorId) {
          console.error(
            "PPP | updateMessageList | actor of type Nerd is not available!"
          );
          return;
        }
      }

      // console.log("PPP | updateMessageList | final actor:", actor);
      // console.log("PPP | updateMessageList | final actorId:", actorId);

      // const chatMessage = getMessageBody({ message, actor, type });
      const chatMessage = getMessageBodyForServerSync({
        message,
        actor,
        actorId,
        type,
      });
      // console.log("PPP | updateMessageList | chatMessage:", chatMessage);

      dispatch({
        type: "ADD_MESSAGE",
        payload: chatMessage,
      });

      //console.log("PPP | updateMessageList | chatMessage:", chatMessage);
      if (!chatMessage) {
        console.error(
          "PPP | updateMessageList | chatMessage is not available!"
        );
        return;
      }
      addMessageToSyncQueue(chatMessage);
    },
    [
      addMessageToSyncQueue,
      dispatch,
      globalState.chatParticipants.allNerdsList,
      globalState.chatRoom.oneOnOneWithNerdId,
      globalState.chatParticipants?.currentUser?.serverUser,
    ]
  ); //

  /**
   * Remove message from sync queue
   */
  const removeMessageFromSyncQueue = useCallback(
    (messageId) => {
      // console.log("removeMessageFromSyncQueue | messageId:", messageId);
      dispatch({
        type: "REMOVE_MESSAGE_FROM_SYNC_QUEUE_BY_ID",
        payload: messageId,
      });
    },
    [dispatch]
  ); //

  /**
   * Process messages in messageSyncQueue
   */

  /**
   * When a message is synced to the server, the stored message ( with additional meta ) is returned.
   * We want to store that DB message in the messageList to replace the temp. message that was returned from the LLM.
   */
  const replaceMessageInMessageList = useCallback(
    (messageId, message) => {
      console.log(
        `replaceMessageInMessageList | messageId: ${messageId} | message: ${message.message}`
      );

      if (!messageId || !message) {
        console.error(
          "replaceMessageInMessageList | messageId or message is not available!"
        );
        return;
      }

      const messageIndex = globalState.chatMessages.messageList.findIndex(
        (msg) => msg.frontEndUniqueMessageId === messageId
      );

      if (messageIndex === -1) {
        console.error(
          "replaceMessageInMessageList | messageIndex is not available!"
        );
        return;
      }

      const updatedMessageList = [...globalState.chatMessages.messageList];
      updatedMessageList[messageIndex] = message;

      /* console.log("replaceMessageInMessageList | updatedMessageList:", {
        updatedMessageList,
      }); */

      dispatch({
        type: "SET_MESSAGE_LIST",
        payload: updatedMessageList,
      });

      return true;
    },
    [dispatch, globalState.chatMessages.messageList]
  ); //

  /**
   * Send message to socket and update messageList
   */
  const handleMessageSend = useCallback(
    (message) => {
      // console.log("handleMessageSend | message:", message);
      if (!globalState.chatRoom.currentChatRoom) return;

      updateMessageList(message, "userMessage");

      dispatch({ type: ACTION_TYPES.SET_TRIGGER_SCROLL, payload: true });

      sendStreamChat({
        chatId: globalState.chatRoom.currentChatRoom,
        socketIOClientId: globalState.chatSocket.socketIOClientId,
        message,
        history: globalState.chatMessages.messageList,
        contactId: globalState.chatMessages.currentUser,
        time: new Date(),
      })
        .then((data) => {
          if (data.status === 200) {
            dispatch({
              type: ACTION_TYPES.SET_TRIGGER_SCROLL,
              payload: true,
            });
          }
        })
        .catch((e) => {
          console.error("handleMessageSend | Error: ", e);
          toast.error("Error sending message");
        });
    },
    [
      globalState.chatRoom.currentChatRoom,
      globalState.chatMessages.messageList,
      globalState.chatMessages.currentUser,
      globalState.chatSocket.socketIOClientId,
      updateMessageList,
      dispatch,
    ]
  ); //

  /**
   * Reset Chat Messages
   * */
  const resetChatMessages = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET_CHAT_MESSAGES });
  }, [dispatch]);

  const pushTokenToMessageList = useCallback((prevMessages, token) => {
    return new Promise((resolve, reject) => {
      console.log(
        "Utils.js | pushTokenToMessageList | prevMessages:",
        prevMessages
      );
      console.log("Utils.js | pushTokenToMessageList | token:", token);

      let lastMessage = null;

      if (prevMessages && prevMessages.length > 0) {
        const lastMessageMaybe = prevMessages[prevMessages.length - 1];
        if (lastMessageMaybe.type === "token") {
          lastMessage = lastMessageMaybe;
        }
      }

      console.log(
        "Utils.js | pushTokenToMessageList | lastMessage:",
        lastMessage
      );

      if (
        prevMessages &&
        prevMessages.length > 0 &&
        lastMessage &&
        lastMessage.type === "token"
      ) {
        // Update exsting token stream, adding a new token
        const newText = `${lastMessage.message}${token}`;
        console.log("Utils.js | pushTokenToMessageList | newText:", newText);

        const newMessage = { ...lastMessage, message: newText };
        console.log(
          "Utils.js | pushTokenToMessageList | newMessage:",
          newMessage
        );

        const messageToReturnLastAsUpdatedToken = prevMessages.slice(0, -1);
        messageToReturnLastAsUpdatedToken.push(newMessage);
        console.log(
          "Utils.js | pushTokenToMessageList | ADDING TOKEN:",
          messageToReturnLastAsUpdatedToken
        );
        resolve(messageToReturnLastAsUpdatedToken);
      }

      const newMessage = { message: token, type: "token" };
      console.log(
        "Utils.js | pushTokenToMessageList | newMessage:",
        newMessage
      );

      const messageToReturnLastAsInitialToken = prevMessages;

      messageToReturnLastAsInitialToken.push(newMessage);
      console.log(
        "Utils.js | pushTokenToMessageList | INITIAL TOKEN:",
        messageToReturnLastAsInitialToken
      );
      resolve(messageToReturnLastAsInitialToken);
    });
  }, []);

  return {
    handleMessageSend,
    resetChatMessages,
    replaceMessageInMessageList,
    removeMessageFromSyncQueue,
    updateMessageList,
    addMessageToSyncQueue,
    getMessageBodyForServerSync,
    pushTokenToMessageList,
  };
};
