// MainChatContext.jsx ( Combined multiple contexts into one )
import React, { createContext, useEffect, useReducer } from "react";

import { useChatRoomEffects } from "./effects/ChatRoomEffects";
import { useChatThreadsEffects } from "./effects/ChatThreadsEffects";
import { useChatMessagesEffects } from "./effects/ChatMessagesEffects";
import { useChatSocketEffects } from "./effects/ChatSocketEffects";
import { useChatParticipantsEffects } from "./effects/ChatParticipantsEffects";
import { useChatInitEffects } from "./effects/ChatInitEffects";

import { useChatThreadsCallBacks } from "app/contexts/chat/callbacks/ChatThreadsCallBacks";
import { useChatSocketCallBacks } from "app/contexts/chat/callbacks/ChatSocketCallBacks";
import { useChatRoomCallBacks } from "app/contexts/chat/callbacks/ChatRoomCallBacks";
import { useChatParticipantsCallBacks } from "app/contexts/chat/callbacks/ChatParticipantsCallBacks";
import { useChatMessagesCallBacks } from "app/contexts/chat/callbacks/ChatMessagesCallBacks";
import { useChatInitCallBacks } from "app/contexts/chat/callbacks/ChatInitCallBacks";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";

import { REDUCERS, INITIAL_STATE } from "./reducers/Reducers";

export const MainChatContext = createContext({
  ...INITIAL_STATE,
  dispatch: () => {},
  reset: () => {},
});

export const combinedReducer = (state = {}, action) => {
  // console.log("MainChatContext | combinedReducer | action:", action);

  // global actions
  if (action.type === ACTION_TYPES.RESET_ALL) {
    return INITIAL_STATE;
  }

  // Reducers aggregation (see ./Reducers.jsx)
  let newState = {};
  let wasActionHandledByAnyReducer = false;
  let wasActionHandledByMultipleReducers = false;
  for (let [key, reducer] of Object.entries(REDUCERS)) {
    const { newStateForKey, actionWasHandled } = reducer(state[key], action);

    // Check if action was handled by any reducer or handled multiple times
    if (actionWasHandled) {
      if (wasActionHandledByAnyReducer) {
        wasActionHandledByMultipleReducers = true;
      } else if (!wasActionHandledByAnyReducer) {
        wasActionHandledByAnyReducer = true;
      }
    }

    newState[key] = newStateForKey;
  }
  if (!wasActionHandledByAnyReducer) {
    console.warn("Action was not handled by any reducer");
  }
  if (wasActionHandledByMultipleReducers) {
    console.warn("Action was handled by multiple reducers");
  }

  return newState;
};

export const MainChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combinedReducer, INITIAL_STATE);

  // console.log("TTT | MainChatContext | state:", state);

  // Import domain specific use-effects, based on routes.
  useChatRoomEffects(state.chatRoom, dispatch, state);
  useChatThreadsEffects(state.chatThreads, dispatch, state);
  useChatMessagesEffects(state.chatMessages, dispatch, state);
  useChatSocketEffects(state.chatSocket, dispatch, state);
  useChatParticipantsEffects(state.chatParticipants, dispatch, state);
  useChatInitEffects(state.chatInitialization, dispatch, state);

  useEffect(() => {
    return () => {
      console.log("UNMOUNTING. Dispatching RESET_ALL");
      dispatch({ type: ACTION_TYPES.RESET_ALL });
    };
  }, []);

  const reset_all = () => {
    console.log("MainChatContext | reset_all");
    dispatch({ type: ACTION_TYPES.RESET_ALL });
  };

  const CALL_BACKS = {
    chatThreads: useChatThreadsCallBacks(state, dispatch),
    chatSocket: useChatSocketCallBacks(state, dispatch),
    chatRoom: useChatRoomCallBacks(state, dispatch),
    chatParticipants: useChatParticipantsCallBacks(state, dispatch),
    chatMessages: useChatMessagesCallBacks(state, dispatch),
    chatInitialization: useChatInitCallBacks(state, dispatch),
  };

  const GLOBAL_CALL_BACKS = {
    globalCallback: {
      reset_all: reset_all,
    },
  };

  const VALUES = {
    state,
    callbacks: { ...GLOBAL_CALL_BACKS, ...CALL_BACKS },
    dispatch,
    reset_all,
  };

  // console.log("MainChatContext | VALUES:", VALUES);

  return (
    <MainChatContext.Provider value={VALUES}>
      {children}
    </MainChatContext.Provider>
  );
};
