const apiKey = process.env.REACT_APP_FIREBASE_APT_KEY;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const firebaseConfig = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

export const auth0Config = {
  client_id,
  domain,
};

const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
const backendApiProtocol = process.env.REACT_APP_BACKEND_API_PROTOCOL;
const backendApiPoirt = process.env.REACT_APP_BACKEND_API_PORT;

export const nodejsConfig = {
  backendApiUrl,
  backendApiProtocol,
  backendApiPoirt,
  backendApiFull: backendApiPoirt
    ? `${backendApiProtocol}://${backendApiUrl}:${backendApiPoirt}`
    : `${backendApiProtocol}://${backendApiUrl}`,
};


const Environment = process.env.REACT_APP_NODE_ENV;
export const setup = {
  environment: Environment,
}