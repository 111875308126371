/* CHAT ROOM */

import { ACTION_TYPES } from "../actions/ActionTypes";

export const initialChatRoomState = {
  currentChatRoom: false,
  /* currentChatRoom: {
    chatId: null,
    participants: [],
  }, */
  triggerScrollToBottom: false, //
  oneOnOneWithNerdId: null, //
  clearTimeouts: false,
  triggerUpdateCurrentChatRoom: false,
  triggerUpdateCurrentChatRoomByChatId: null,
};

export const chatRoomReducer = (state, action) => {
  let actionMatched = true; // Default to true

  const newState = (() => {
    /* if (action.type === ACTION_TYPES.RESET_ALL) {
      // Maybe do some special sidebar-specific reset logic here
      return { ...initialChatRoomState, clearTimeouts: true };
    } */

    switch (action.type) {
      case ACTION_TYPES.SET_CURRENT_CHAT_ROOM:
        return { ...state, currentChatRoom: action.payload };
      case ACTION_TYPES.SET_ONEONONE_WITH_NERDID:
        return { ...state, oneOnOneWithNerdId: action.payload };
      case ACTION_TYPES.SET_TRIGGER_SCROLL:
        return { ...state, triggerScrollToBottom: action.payload };
      case ACTION_TYPES.SET_CLEAR_TIMEOUTS:
        return { ...state, clearTimeouts: action.payload };
      case ACTION_TYPES.RESET_CHAT_ROOM:
        return initialChatRoomState;
      case ACTION_TYPES.UPDATE_CURRENT_CHAT_ROOM:
        return {
          ...state,
          triggerUpdateCurrentChatRoom: action.payload.action || false,
          triggerUpdateCurrentChatRoomByChatId: action.payload?.chatId || false,
        };
      default:
        actionMatched = false;
        return { ...state };
    }
  })();

  return [newState, actionMatched];
};

export const chatRoomReducerFetch = (state, action) => {
  const [newState, actionWasHandled] = chatRoomReducer(state, action);
  if (actionWasHandled) {
    // console.log("chatRoomReducerFetch | action:", action);
    /* console.log("------------------------");
    console.log("chatRoomReducerFetch | action:", action);
    console.log("chatRoomReducerFetch | state:", state);
    console.log("chatRoomReducerFetch | newState:", newState);
    console.log("------------------------"); */
  }
  return { newStateForKey: newState, actionWasHandled };
};
