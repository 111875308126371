import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  initializeSocket,
  disconnectSocket,
} from "app/views/chat-box/socketManager"; // Import socket functions

import { useRefs } from "app/contexts/RefsContext";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";
import { useChatSocketCallBacks } from "app/contexts/chat/callbacks/ChatSocketCallBacks";

export const useChatSocketEffects = (state, dispatch, globalState) => {
  const { socketRef } = useRefs();

  /**
   * Initialize socket connection and listen for events
   */
  useEffect(() => {
    if (globalState?.chatInitialization?.chatInitialized) {
      // console.log("SOCKET UPDATE | Initializing socket connection");
      /* console.log(
        "SOCKET UPDATE | socketRef.current?.id - ",
        socketRef.current?.id
      ); */

      socketRef.current = initializeSocket({
        connect: () => {
          // console.log("SOCKET UPDATE | Socket connected");
          dispatch({
            type: "SET_SOCKET_ID",
            payload: socketRef.current.id,
          });
          // console.log("SOCKET UPDATE | Socket connected");
        },
        disconnect: () => {
          // console.warn("SOCKET UPDATE | Socket disconnected");
        },
        message: (data) => {
          // console.log("SOCKET UPDATE | Message received:", data);
        },
        token: async (token) => {
          // console.log(`SOCKET UPDATE | Token received: ${token}`);
          if (!globalState?.chatMessages?.streamingMessage) {
            dispatch({
              type: "SET_STREAMING_MESSAGE",
              payload: true,
            });
            if (state.tokenStream.length > 0) {
              dispatch({
                type: ACTION_TYPES.CLEAR_STREAM_ARRAY,
              });
            }
          }
          if (token.length > 0) {
            dispatch({
              type: ACTION_TYPES.PUSH_TOKEN_TO_STREAM_ARRAY,
              payload: token,
            });
            dispatch({
              type: ACTION_TYPES.SET_TRIGGER_SCROLL,
              payload: true,
            });
          } else {
            // console.log("SOCKET UPDATE | Token received is empty");
          }
        },
        // how to know when the token stream is completed? I need a proper event
        end: () => {
          // console.log("SOCKET UPDATE | Token stream completed");
          dispatch({
            type: ACTION_TYPES.SET_TRIGGER_SCROLL,
            payload: true,
          });

          dispatch({
            type: ACTION_TYPES.SET_STREAMING_MESSAGE,
            payload: false,
          });

          dispatch({
            type: ACTION_TYPES.SET_SYNC_STREAM_MESSAGE,
            payload: true,
          });
        },
      });

      // Modified logic to print all event names
      const originalOnevent = socketRef.current.onevent;

      socketRef.current.onevent = function (packet) {
        const eventName = packet.data[0];
        // console.log("SOCKET UPDATE | Event received:", eventName);
        // Call the original onevent function to ensure the event is processed normally
        if (originalOnevent) {
          originalOnevent.call(this, packet);
        }
      };
    }

    return () => {
      disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketRef, dispatch, globalState?.chatInitialization?.chatInitialized]);

  /**
   * Disconnect socket on unmount
   */
  useEffect(() => {
    return () => {
      /* console.log(
        "SOCKET UPDATE | useChatSocketEffects | Disconnecting socket"
      ); */
      disconnectSocket();
    };
  }, []);
};
