/* CHAT SOCKET */

import { ACTION_TYPES } from "../actions/ActionTypes";

export const initialChatSocketState = {
  socketIOClientId: "",
  tokenStream: [],
  FiFoToken: false,
  tokenProcessHeartbeat: false,
};

export const chatSocketReducer = (state, action) => {
  let actionMatched = true; // Default to true

  const newState = (() => {
    /* if (action.type === ACTION_TYPES.RESET_ALL) {
      // Maybe do some special sidebar-specific reset logic here
      return initialChatSocketState;
    } */

    switch (action.type) {
      case ACTION_TYPES.SET_SOCKET_ID:
        return { ...state, socketIOClientId: action.payload };
      case ACTION_TYPES.RESET_CHAT_SOCKET:
        return initialChatSocketState;
      case ACTION_TYPES.PUSH_TOKEN_TO_STREAM_ARRAY:
        return {
          ...state,
          tokenStream: [...state.tokenStream, action.payload],
        };
      case ACTION_TYPES.CLEAR_STREAM_ARRAY:
        return {
          ...state,
          tokenStream: [],
        };
      case ACTION_TYPES.POP_TOKEN_FROM_STREAM_ARRAY:
        const FiFoToken = state.tokenStream[0]; // remove FiFo token
        return {
          ...state,
          FiFoToken: FiFoToken ? FiFoToken : "",
          tokenStream: state.tokenStream.slice(1),
        };

      case ACTION_TYPES.CLEAR_FIFO_TOKEN:
        return {
          ...state,
          FiFoToken: false,
        };
      case ACTION_TYPES.SET_TOKEN_PROCESS_HEARTBEAT:
        return {
          ...state,
          tokenProcessHeartbeat: action.payload,
        };
      default:
        actionMatched = false;
        return { ...state };
    }
  })();

  return [newState, actionMatched];
};

export const chatSocketReducerFetch = (state, action) => {
  const [newState, actionWasHandled] = chatSocketReducer(state, action);
  if (actionWasHandled) {
    // console.log("chatSocketReducerFetch | action:", action);
    /* console.log("--------------------");
    console.log("chatSocketReducerFetch | action:", action);
    console.log("chatSocketReducerFetch | state:", state);
    console.log("chatSocketReducerFetch | newState:", newState);
    console.log("--------------------"); */
  }
  return { newStateForKey: newState, actionWasHandled };
};
