import React, {
  useReducer,
  useCallback,
  createContext,
  useEffect,
} from "react";
import { getAllNerds } from "app/services/ChatService";
import { toast } from "react-toastify";
import useMainAuth from "app/hooks/useMainAuth";

export const MainNerdsContext = createContext();

const initialState = {
  contactList: null,
  nerdsFetched: false,
  modalOpen: false,
  selectNerdModalOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTACT_LIST":
      return { ...state, contactList: action.payload };
    case "SET_MODAL_STATE":
      return { ...state, modalOpen: action.payload };
    case "SET_SELECT_NERD_MODAL_STATE":
      return { ...state, selectNerdModalOpen: action.payload };
    case "SET_NERDS_FETCHED":
      return { ...state, nerdsFetched: action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export const NerdsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { firebase, nodeServer } = useMainAuth();

  const getNerds = useCallback(async () => {
    console.log("getNerds | Fetching AI Nerd list...");
    await getAllNerds()
      .then((data) => {
        console.log("getNerds | data:", data);

        if (data.status >= 300) {
          throw new Error("Failed to load AI Nerd list");
        }

        if (data.data && data.data.length === 0) {
          console.log("getNerds | No AI Nerd found yet");
          toast.info("Please create your first AI Nerd");
          dispatch({ type: "SET_CONTACT_LIST", payload: [] });
          return true;
        }

        if (data.status === 204) {
          console.log("getNerds | No AI Nerd found yet");
          toast.info("Please create your first AI Nerd");
          dispatch({ type: "SET_CONTACT_LIST", payload: [] });
          return true;
        }

        if (data.data && data.data.length > 0) {
          console.log("getNerds | AI Nerd list loaded");
          dispatch({ type: "SET_CONTACT_LIST", payload: data.data });
        }

        return true;
      })
      .catch((error) => {
        console.log("getNerds | error:", error);
        toast.error(error.message);
        return false;
      });
  }, []);

  const setSelectNerdModalOpen = (value) => {
    dispatch({ type: "SET_SELECT_NERD_MODAL_STATE", payload: value });
  };

  const reset = () => dispatch({ type: "RESET" });

  useEffect(() => {
    if (firebase.isAuthenticated && nodeServer.isAuthenticated) {
      if (!state.contactList && !state.nerdsFetched) {
        dispatch({ type: "SET_NERDS_FETCHED", payload: true });
        getNerds();
      }
    }
  }, [
    firebase.isAuthenticated,
    getNerds,
    nodeServer.isAuthenticated,
    state.contactList,
    state.nerdsFetched,
  ]);

  return (
    <MainNerdsContext.Provider
      value={{
        ...state,
        dispatch,
        getNerds,
        onModalClose: (value) => {
          dispatch({ type: "SET_MODAL_STATE", payload: value });
        },
        setSelectNerdModalOpen,
        reset,
      }}
    >
      {children}
    </MainNerdsContext.Provider>
  );
};
