import axios from "app/utils/axiosConfig";

/**
 * USER ENDPOINTS
 */

export const fireNerd = async ({ nerdId }) => {
  return await axios.delete(`/v1/nerds/${nerdId}`);
};

export const hireNerd = async (payload) => {
  return await axios.post(`/v1/nerds/`, payload);
};

export const hireNerdNoTemplate = async () => {
  return await axios.post(`/v1/nerds/`, {
    nerd: {
      name: "Temp Nerd Name",
    },
  });
};

export const generateAvatarName = async (config) => {
  try {
    const response = await axios.post("/v1/avatars/generateName", config);
    return response.data;
  } catch (error) {
    console.error("Error generating avatar name:", error);
    throw error;
  }
};
export const generateAvatarImage = async (config) => {
  try {
    const response = await axios.post("/v1/avatars/generateImage", config);
    return response.data;
  } catch (error) {
    console.error("Error generating avatar image:", error);
    throw error;
  }
};

export const getAllNerdsToHire = async () => {
  return await axios.get("/v1/nerds/hire");
};

export const getAllNerds = async () => {
  return await axios.get("/v1/nerds/");
};

/**
 * ADMIN ONLY ENDPOINTS
 */
export const createNerdTemplate = async (data) => {
  return await axios.post("/v1/nerds/hire", data);
};
