import {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { MatxLoading } from "app/components";
import { firebaseConfig } from "config.js";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import useSettings from "app/hooks/useSettings";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const initialAuthState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  triggerServerVerifyFirebaseToken: false,
  triggerServerLogout: false,
  firebaseAccessToken: null,
};

const reducer = (state, action) => {
  console.log(">>> reducer | action -> ", action);
  console.log(">>> reducer | state -> ", state);
  switch (action.type) {
    case "FB_AUTH_STATE_CHANGED": {
      const { isAuthenticated, user, tokens } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        firebaseAccessToken: tokens,
      };
    }
    case "TRIGGER_SERVER_VERIFY_TOKEN": {
      const { trigger } = action.payload;
      return { ...state, triggerServerVerifyFirebaseToken: trigger };
    }
    case "TRIGGER_SERVER_LOGOUT": {
      const { trigger } = action.payload;
      return { ...state, triggerServerLogout: trigger };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "FIREBASE",
  createUserWithEmail: (email, password) => Promise.resolve(),
  signInWithEmail: (email, password) => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  deTrigger_triggerServerVerifyFirebaseToken: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { settings } = useSettings();
  const intervalRef = useRef(null); // Reference to hold the interval

  const signInWithEmail = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const createUserWithEmail = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    console.log(">>> Firebase Main logout");
    dispatch({
      type: "TRIGGER_SERVER_LOGOUT",
      payload: { trigger: true },
    });
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const tokens = await user.getIdToken();
        if (!tokens) {
          toast.error("Error getting Firebase token");
          return;
        }

        dispatch({
          type: "FB_AUTH_STATE_CHANGED",
          payload: {
            isAuthenticated: true,
            tokens,
            user: {
              ...user,
              id: user.uid,
              email: user.email,
              avatar: user.photoURL,
              name: user.displayName || user.email,
            },
          },
        });
      } else {
        dispatch({
          type: "FB_AUTH_STATE_CHANGED",
          payload: { isAuthenticated: false, user: null },
        });
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        console.log(
          ">>> FirebaseAuthContext | useEffect | onIdTokenChanged | NEW user -> ",
          user
        );
        const token = await user.getIdToken();
        if (!token) {
          console.error(
            ">>> FirebaseAuthContext | useEffect | onIdTokenChanged | Error getting Firebase token"
          );
          toast.error("Firebase authentification error #3");
          toast.info("You will be logged out");
          logout();
          return;
        }

        Cookies.set("token", token);

        dispatch({
          type: "TRIGGER_SERVER_VERIFY_TOKEN",
          payload: { trigger: true },
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const checkTokenExpiration = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      // Force refresh the token
      const newToken = await user.getIdToken(true);
      if (!newToken) {
        console.error(
          ">>> checkTokenExpiration | Error getting Firebase token"
        );
        toast.error("Firebase authentification error #2");
        toast.info("You will be logged out");
        logout();
        return;
      }
      Cookies.set("token", newToken);

      dispatch({
        type: "TRIGGER_SERVER_VERIFY_TOKEN",
        payload: { trigger: true },
      });
    }
  }, [auth.currentUser]);

  useEffect(() => {
    // If there's no existing interval, set one
    if (!intervalRef.current) {
      // Set an interval to check every 50 minutes (3000000 milliseconds)
      // intervalRef.current = setInterval(checkTokenExpiration, 3000000);
      // intervalRef.current = setInterval(checkTokenExpiration, 60000); // 1 minute
      intervalRef.current = setInterval(checkTokenExpiration, 30000); // 1 minute
    }

    // Cleanup on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null; // Clear the reference to ensure no stale intervals
      }
    };
  }, []);

  useEffect(() => {
    console.log("state: ");
    console.log(state);

    console.log("state.triggerServerVerifyFirebaseToken: ");
    console.log(state.triggerServerVerifyFirebaseToken);
  }, [state]);

  const deTrigger_triggerServerVerifyFirebaseToken = () => {
    dispatch({
      type: "TRIGGER_SERVER_VERIFY_TOKEN",
      payload: { trigger: false },
    });
  };

  const deTrigger_triggerServerLogout = () => {
    dispatch({
      type: "TRIGGER_SERVER_LOGOUT",
      payload: { trigger: false },
    });
  };

    if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        signInWithGoogle,
        method: "FIREBASE",
        signInWithEmail,
        createUserWithEmail,
        deTrigger_triggerServerVerifyFirebaseToken,
        deTrigger_triggerServerLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
