import { useCallback } from "react";
import { toast } from "react-toastify";
import {
  generateChatName,
  setChatName,
  getChatById,
  getChatMessagesByChatId,
} from "app/services/ChatService";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";

import { useChatThreadsCallBacks } from "./ChatThreadsCallBacks";
import { useMemo } from "react";

export const useChatRoomCallBacks = (globalState, dispatch) => {
  const { getChatListUpdate } = useChatThreadsCallBacks(globalState, dispatch);

  /**
   * Update currentChatRoom
   */
  const updateCurrentChatRoom = useCallback(
    (props) => {
      if (
        globalState?.chatRoom?.currentChatRoom?.chatId?.length > 0 &&
        globalState?.chatRoom?.oneOnOneWithNerdId?.length > 0 &&
        globalState?.chatSocket?.tokenStream?.length === 0 &&
        globalState?.chatSocket?.FiFoToken === false &&
        globalState?.chatMessages?.streamingMessage === false &&
        globalState?.chatMessages?.syncLastMsgToServerTrigger === false
      ) {
        /* console.log(
          "updateCurrentChatRoom | globalState.chatRoom.currentChatRoom:",
          globalState.chatRoom.currentChatRoom
        ); */

        if (globalState.chatMessages.syncLastMsgToServerTrigger === false) {
          dispatch({
            type: "SET_INITIAL_MESSAGE_LIST_FETCH_COMPLETED",
            payload: true,
          });
        }

        const chatId = props?.chatId || null;
        if (globalState.chatRoom.currentChatRoom) {
          // optional chatId param to update chat room with a different chatId
          // default is to use the currentChatRoom.chatId
          const newChatId =
            chatId || globalState.chatRoom.currentChatRoom.chatId;

          // console.log("updateCurrentChatRoom | newChatId:", newChatId);
          getChatById({ chatId: newChatId })
            .then((data) => {
              // console.log("updateCurrentChatRoom | data:", data);
              if (data.status === 200) {
                dispatch({
                  type: "SET_CURRENT_CHAT_ROOM",
                  payload: data.data,
                });
                dispatch({
                  type: "SET_MESSAGE_LIST",
                  payload: data.data.messages || [],
                });
                dispatch({
                  type: ACTION_TYPES.SET_TRIGGER_SCROLL,
                  payload: true,
                });
              }
            })
            .then(() => {
              /* console.log(
                "updateCurrentChatRoom | getChatListUpdate (before) | globalState:",
                globalState
              ); */
              getChatListUpdate(); // TODO: Note this change may break logic.
              /* console.log(
                "updateCurrentChatRoom | getChatListUpdate (after) | globalState:",
                globalState
              ); */
              dispatch({
                type: ACTION_TYPES.SET_TRIGGER_UPDATE_CHAT_THREADS_LIST,
                payload: true,
              });
              getChatMessagesByChatId({ chatId: newChatId }).then((data) => {
                // console.log("updateCurrentChatRoom | data:", data);
                if (data.status === 200) {
                  dispatch({
                    type: "SET_MESSAGE_LIST",
                    payload: data.data,
                  });
                  dispatch({
                    type: ACTION_TYPES.SET_TRIGGER_SCROLL,
                    payload: true,
                  });
                }
              });
            })
            .catch((e) => {
              console.error("updateCurrentChatRoom | error:", e);
              toast.error("Error updating chat room");
            });
        }
      }
    },
    [dispatch, getChatListUpdate, globalState]
  ); //

  /**
   * Set opponent user ( currently single user, but will be an array of users later )
   */
  const setOneOnOneWithNerdId = useCallback(
    (nerdId) => {
      if (!nerdId) {
        return;
      }
      if (typeof nerdId !== "string") {
        console.error("setOneOnOneWithNerdId | nerdId is not a string!");
        return;
      }
      // console.log("setOneOnOneWithNerdId | nerdId:", nerdId);
      dispatch({
        type: ACTION_TYPES.SET_ONEONONE_WITH_NERDID,
        payload: nerdId,
      });
    },
    [dispatch]
  );

  /**
   * Returns a count of messages that are not tokens and that already have a server message ID.
   */
  const getCompletedMessagesLengthMemo = useMemo(() => {
    const currentMessages = globalState.chatMessages.messageList;

    const currentCompletedMessages = currentMessages.filter(
      (message) => message.chatMessageId && message.type !== "token"
    );

    return currentCompletedMessages.length;
  }, [globalState.chatMessages.messageList]);

  const getCompletedMessagesLength = useCallback(() => {
    return getCompletedMessagesLengthMemo;
  }, [getCompletedMessagesLengthMemo]);

  return {
    setOneOnOneWithNerdId,
    updateCurrentChatRoom,
    getCompletedMessagesLength,
  };
};
