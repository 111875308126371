import { Box, Button, Card, Divider, Grid, styled } from "@mui/material";
import { FlexBetween } from "app/components/FlexBox";
import { H4, H5, Span } from "app/components/Typography";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import PaymentDialog from "./PaymentDialog";

const CartRoot = styled(Card)(({ theme }) => ({
  margin: "30px",
  padding: "24px",
  [theme.breakpoints.down("sm")]: { margin: "16px", padding: "16px " },
}));

const CheckoutStep = () => {
  const [open, setOpen] = useState(false);
  const cart = useSelector((state) => state.cart);

  const getTotalCost = () => {
    return cart.reduce((prev, curr) => prev + curr.price * curr.qty, 0);
  };

  const toggleDialog = () => setOpen(!open);

  const initialValues = {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    mobile: "",
    country: "",
    city: "",
    address: "",
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  return (
    <CartRoot className="checkout">
      <H4 mb={3}>Billing Details</H4>

      <Grid item lg={4} md={5} xs={12}>
        <FlexBetween mb={2}>
          <H5>Porduct</H5>
          <H5>Total Price</H5>
        </FlexBetween>

        <Box>
          {cart.map((product, ind) => (
            <Fragment key={product.id}>
              <FlexBetween py={2}>
                <Span color="text.secondary">{product.title}</Span>
                <Span color="text.secondary">
                  ${product.price * product.qty}
                </Span>
              </FlexBetween>

              {ind !== cart.length - 1 && <Divider />}
            </Fragment>
          ))}

          <FlexBetween mt={2} mb={4}>
            <H5>Total</H5>
            <H5>${getTotalCost().toFixed(2)}</H5>
          </FlexBetween>

          <Button
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            onClick={() => setOpen(!open)}
          >
            Place Order
          </Button>
        </Box>
      </Grid>

      <PaymentDialog open={open} toggleDialog={toggleDialog} />
    </CartRoot>
  );
};

export default CheckoutStep;
