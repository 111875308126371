/**
 * Get messages from messageList
 */
export const getMessagesFromList = (messages) => {
  // console.log("getMessagesFromList | messages:", messages);

  return messages
    .map((message) => {
      const messageType =
        message.MessageFromType === "Nerd" ? "ai_assistant" : "human";

      return `${messageType} ${message.message}`;
    })
    .join(" ");
};

/**
 * Takes the existing messageList and pushes the token to the last message without creating a new message
 */
/* export const pushTokenToMessageList = (prevMessages, token) => {
  return new Promise((resolve, reject) => {
    console.log(
      "Utils.js | pushTokenToMessageList | prevMessages:",
      prevMessages
    );
    console.log("Utils.js | pushTokenToMessageList | token:", token);

    let lastMessage = null;

    if (prevMessages && prevMessages.length > 0) {
      const lastMessageMaybe = prevMessages[prevMessages.length - 1];
      if (lastMessageMaybe.type === "token") {
        lastMessage = lastMessageMaybe;
      }
    }

    console.log(
      "Utils.js | pushTokenToMessageList | lastMessage:",
      lastMessage
    );

    if (
      prevMessages &&
      prevMessages.length > 0 &&
      lastMessage &&
      lastMessage.type === "token"
    ) {
      const newText = `${lastMessage.message}${token}`;
      console.log("Utils.js | pushTokenToMessageList | newText:", newText);

      const newMessage = { ...lastMessage, message: newText };
      // Update exsting token stream, adding a new token
      const messageToReturnLastAsUpdatedToken = prevMessages.slice(0, -1);
      messageToReturnLastAsUpdatedToken.push(newMessage);
      console.log(
        "Utils.js | pushTokenToMessageList | ADDING TOKEN:",
        messageToReturnLastAsUpdatedToken
      );
      resolve(messageToReturnLastAsUpdatedToken);
    }

    lastMessage = { message: "", type: "token" };
    const newMessage = { message: token, type: "token" };

    const messageToReturnLastAsInitialToken = prevMessages;
    messageToReturnLastAsInitialToken.push(newMessage);
    console.log(
      "Utils.js | pushTokenToMessageList | INITIAL TOKEN:",
      messageToReturnLastAsInitialToken
    );
    resolve(messageToReturnLastAsInitialToken);
  });
}; */
