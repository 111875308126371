import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from "react";
import useSettings from "app/hooks/useSettings";
import axiosInstance from "app/utils/axiosConfig";
import { MatxLoading } from "app/components";
import useMainAuth from "app/hooks/useMainAuth";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const initialAuthState = {
  serverIsInitialised: false,
  serverIsAuthenticated: false,
  serverUser: null,
  serverUserTokens: null,
  updating: false,
  verifying: false,
};

const reducer = (state, action) => {
  //console.log(">>> reducer | action -> ", action);
  //console.log(">>> reducer | state -> ", state);

  switch (action.type) {
    case "SERVER_AUTH_STATE_CHANGED": {
      const { serverIsAuthenticated, serverUser, serverUserTokens } =
        action.payload;
      const newState = {
        ...state,
        serverIsAuthenticated,
        serverIsInitialised: true,
        serverUser,
        serverUserTokens,
      };
      return newState;
    }

    case "RESET": {
      return { ...initialAuthState, serverIsInitialised: true };
    }

    case "CLEAR": {
      return { ...initialAuthState, serverIsInitialised: false };
    }

    case "UPDATE_USER_TOKENS": {
      const { trigger } = action.payload;
      return { ...state, updating: trigger };
    }

    case "VERIFY_USER": {
      const { trigger } = action.payload;
      return { ...state, verifying: trigger };
    }

    case "SERVER_AUTH_NEW_TOKENS": {
      const { serverUserTokens } = action.payload;
      return { ...state, serverUserTokens };
    }

    default: {
      return state;
    }
  }
};

const ServerContext = createContext({
  ...initialAuthState,
  method: "NODEJS",
  signInWithServer: () => Promise.resolve(),
  logoutServerCB: () => Promise.resolve(),
  clear: () => Promise.resolve(),
  updateUserTokenOnServer: () => Promise.resolve(),
});

export const ServerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { settings } = useSettings();
  const refreshTokensIntervalRef = useRef(null);

  const {
    isAuthenticated,
    firebase,
    triggerServerVerifyFirebaseToken,
    deTrigger_triggerServerVerifyFirebaseToken,
    firebaseAccessToken,
    triggerServerLogout,
    deTrigger_triggerServerLogout,
    logout,
  } = useMainAuth();
  const { user } = firebase;

  // const triggerServerVerifyFirebaseToken = false;

  /* useEffect(() => {
    console.log("triggerServerVerifyFirebaseToken - ");
    console.log(triggerServerVerifyFirebaseToken);
  }, [triggerServerVerifyFirebaseToken]); */

  /* useEffect(() => {
    console.log("firebaseAccessToken - ");
    console.log(firebaseAccessToken);
  }, [firebaseAccessToken]); */

  useEffect(() => {
    // This is AXIOS triggering via the Settings context, when any request to the server is 401!
    /* console.log("settings.triggers.server - ");
    console.log(settings.triggers.server); */
    if (settings.triggers.server.triggerRefreshToken) {
      dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: true },
      });
    }

    return () => {
      dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: false },
      });
    };
  }, [settings.triggers.server]);

  // Lower 'updating' Flag when set to true
  useEffect(() => {
    if (state.updating) {
      dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: false },
      });
    }
  }, [state.updating]);

  // Lower 'verifying' Flag when set to true
  useEffect(() => {
    if (state.verifying) {
      dispatch({
        type: "VERIFY_USER",
        payload: { trigger: false },
      });
    }
  }, [state.verifying]);

  const logoutServer = useCallback(async () => {
    try {
      console.log(">>> logoutServer | event");
      const refreshToken = state.serverUserTokens?.refresh?.token;
      if (!refreshToken) return false;

      const response = await axiosInstance({
        url: `/v1/auth/logout/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          refreshToken: JSON.stringify(refreshToken),
        },
      });
      const data = await response.json();
      console.log(">>> logoutServer | data -> ", data);

      dispatch({
        type: "CLEAR",
      });

      return data;
    } catch (error) {
      console.error("Error logout from server:", error);
      return false;
    }
  }, [state.serverUserTokens?.refresh?.token]);

  const logoutServerCB = useCallback(() => logoutServer(), [logoutServer]);

  const verifyWithBackend = useCallback(
    async (idToken) => {
      try {
        const response = await axiosInstance({
          url: `v1/auth/verify-firebase-token`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
        });
        return response.data;
      } catch (error) {
        console.error("Error verifying token with backend:", error);
        console.log("LOGGING OUT, REFRESH TOKEN IS NO LONGER VALID");
        logout(); // Logout in Firebase ( which will trigger logoutServerCB() later )
        return false;
      }
    },
    [logout]
  );

  const signInWithServer = useCallback(
    (accessToken) => {
      // const idToken = await user.getIdToken(true);
      // console.log(">>> signInWithServer | accessToken -> ", accessToken);
      console.log("Begin verifyWithBackend with accessToken -> ", accessToken);
      if (!accessToken) {
        toast.error("Firebase authentification error #1");
        toast.info("You will be logged out");
        logout(); // Logout in Firebase ( which will trigger logoutServerCB() later )
      }

      dispatch({
        type: "VERIFY_USER",
        payload: { trigger: false },
      });

      verifyWithBackend(accessToken)
        .then((response) => {
          Cookies.set("serverToken", response.tokens.access.token);

          if (response.isAuthenticated) {
            axiosInstance.defaults.headers.common[
              "AuthorizationServer"
            ] = `Bearer ${response.tokens.access.token}`;
            dispatch({
              type: "SERVER_AUTH_STATE_CHANGED",
              payload: {
                serverIsAuthenticated: true,
                serverUser: response.user,
                serverUserTokens: response.tokens,
              },
            });
          } else {
            throw new Error("Disaptching RESET | isAuthenticated -> false");
          }
        })
        .catch((error) => {
          console.error("Error verifying token with backend:", error);
          console.log("Disaptching RESET | isAuthenticated -> false");
          /* dispatch({
            type: "RESET",
          }); */
          toast.error("Firebase authentification error #7");
          toast.info("You will be logged out");
          logout(); // Logout in Firebase ( which will trigger logoutServerCB() later )
        });
    },
    [verifyWithBackend]
  );

  const updateUserTokenOnServer = useCallback(async () => {
    try {
      const refreshToken = state.serverUserTokens?.refresh?.token;
      if (
        !state.serverIsInitialised ||
        !state.serverIsAuthenticated ||
        !refreshToken
      ) {
        if (refreshTokensIntervalRef.current) {
          clearInterval(refreshTokensIntervalRef.current);
        }
        return false;
      }

      const config = {
        url: `/v1/auth/refresh-server-tokens`,
        method: "post",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${refreshToken}`,
        },
        data: JSON.stringify({
          refreshToken: refreshToken,
        }),
      };

      // console.log("config:");
      // console.log(config);

      const response = await axiosInstance.request(config);
      // const data = await response.json();
      console.log(
        ">>> updateUserTokenOnServer | response.data -> ",
        response.data
      );

      const newAccessToken = response?.data?.access?.token || null;
      const newRefreshToken = response?.data.refresh?.token || null;

      console.log(
        ">>> updateUserTokenOnServer (refresh-tokens) | newAccessToken -> ",
        newAccessToken
      );
      console.log(
        ">>> updateUserTokenOnServer (refresh-tokens) | newRefreshToken -> ",
        newRefreshToken
      );

      if (!newAccessToken || !newRefreshToken) {
        throw new Error("Disaptching RESET | isAuthenticated -> false");
      }
      const newAuthToken = `Bearer ${newRefreshToken}`;
      if (!newAuthToken) {
        throw new Error("Disaptching RESET | isAuthenticated -> false");
      }
      console.log(
        ">>> updateUserTokenOnServer (refresh-tokens) | newAuthToken -> ",
        newAuthToken
      );

      axiosInstance.defaults.headers.common[
        "AuthorizationServer"
      ] = `Bearer ${newAccessToken}`;
      dispatch({
        type: "SERVER_AUTH_NEW_TOKENS",
        payload: {
          serverUserTokens: {
            access: {
              token: newAccessToken,
            },
            refresh: {
              token: newRefreshToken,
            },
          },
        },
      });
      /* dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: false },
      }); */
      return response.data;
    } catch (error) {
      console.error("Error updating token with backend:", error);
      /* dispatch({
        type: "UPDATE_USER_TOKENS",
        payload: { trigger: false },
      }); */
      /* dispatch({
        type: "VERIFY_USER",
        payload: { trigger: true },
      }); */
      toast.error("Firebase authentification error #4");
      toast.info("You will be logged out");
      logout(); // Logout in Firebase ( which will trigger logoutServerCB() later )
      return false;
    }
  }, [logout, state.serverIsAuthenticated, state.serverIsInitialised, state.serverUserTokens?.refresh?.token]);

  const clear = () => {
    dispatch({
      type: "CLEAR",
    });
  };

  useEffect(() => {
    /* console.log(
      ">>> useEffect | state.serverIsInitialised -> ",
      state.serverIsInitialised
    );
    console.log(
      ">>> useEffect | state.serverIsAuthenticated -> ",
      state.serverIsAuthenticated
    );
    console.log(">>> useEffect | isAuthenticated -> ", isAuthenticated); */

    if (
      !state.serverIsInitialised || // UI is not initialised
      !state.serverIsAuthenticated || // Server is not authenticated
      !isAuthenticated // Firebase not authenticated
    ) {
      console.log(">>> useEffect | clearInterval!!!");
      if (refreshTokensIntervalRef.current) {
        // if interval is set, clear it
        clearInterval(refreshTokensIntervalRef.current);
      }
      return;
    }

    // Check if the interval is not already set
    /* if (!refreshTokensIntervalRef.current) {
      // Call the function immediately if needed
      console.log(
        ">>> updateUserTokenOnServer | event ( due to 'INIT' - SHOULD RUN ONCE ONLY!!!'"
      );
      updateUserTokenOnServer();
      return;
    } */
    console.log(">>> useEffect | setInterval!!!");
    // Set up the interval and store its ID in the useRef
    refreshTokensIntervalRef.current = setInterval(() => {
      console.log("UPDATE USER TOKENS ON SERVER - every 5 minutes");
      updateUserTokenOnServer();
      }, 5 * 60 * 1000); // 5 minutes in milliseconds
      // }, 20 * 1000); // 5 minutes in milliseconds

    // Cleanup: clear the interval when the component is unmounted
    return () => {
      console.log(">>> useEffect | (return / unmount) clearInterval!!!");
      if (refreshTokensIntervalRef.current) {
        clearInterval(refreshTokensIntervalRef.current);
      }
    };
  }, [updateUserTokenOnServer, state.serverIsInitialised, state.serverIsAuthenticated, isAuthenticated]); // Empty dependency array ensures this useEffect runs once when component mounts

  /*
   * SEND FIREBASE TOKEN TO SERVER FOR VERIFICATION ( ON LOGIN OR TOKEN REFRESH )
   * SERVER SHOULD RESPONDS WITH NEW SERVERSIDE TOKENS.
   */
  useEffect(() => {
    if (!settings.isAxiosSetup) {
      return;
    }

    if (triggerServerVerifyFirebaseToken) {
      // console.log("Starting VERIFY_USER");

      return;
    }

    console.log(
      "Local Dispatch Trigger VERIFY_USER Recieved, removing trigger and Disaptching action... "
    );
    if (!state.verifying && triggerServerVerifyFirebaseToken) {
      // prevent infinite loop
      deTrigger_triggerServerVerifyFirebaseToken();
      dispatch({
        type: "VERIFY_USER",
        payload: { trigger: true },
      });
    }

    if (state.verifying && !triggerServerVerifyFirebaseToken) {
      // Waiting for triggerServerVerifyFirebaseToken to be false
      signInWithServer(user?.accessToken);
    }
  }, [state.verifying, triggerServerVerifyFirebaseToken, deTrigger_triggerServerVerifyFirebaseToken, signInWithServer, settings.isAxiosSetup, user?.accessToken]);

  /* useEffect(() => {
    console.log("TEST | triggerServerLogout - ", triggerServerLogout);
    if (triggerServerLogout) {
      console.log(">>> triggerServerLogout | event");
      logoutServerCB();
    }
  }, [triggerServerLogout, logoutServerCB]); */

  /* useEffect(() => {
    if (state.verifying && settings.isAxiosSetup) {
      // console.log("Starting verifyWithBackend");
      dispatch({
        type: "VERIFY_USER",
        payload: { trigger: false },
      });
      verifyWithBackend(user?.accessToken)
        .then((response) => {
          console.log(">>> response -> ", response);
          if (response.isAuthenticated) {
            console.log(
              "Disaptching SERVER_AUTH_STATE_CHANGED | isAuthenticated -> true"
            );
            console.log("SETTING TO: ", response.tokens.access.token);
            axiosInstance.defaults.headers.common[
              "AuthorizationServer"
            ] = `Bearer ${response.tokens.access.token}`;
            dispatch({
              type: "SERVER_AUTH_STATE_CHANGED",
              payload: {
                serverIsAuthenticated: true,
                serverUser: response.user,
                serverUserTokens: response.tokens,
              },
            });
          } else {
            throw new Error("Disaptching RESET | isAuthenticated -> false");
          }
        })
        .catch((error) => {
          console.error("Error verifying token with backend:", error);
          console.log("Disaptching RESET | isAuthenticated -> false");
          dispatch({
            type: "RESET",
          });
        });
    }
  }, [
    settings.isAxiosSetup,
    state.verifying,
    user?.accessToken,
    verifyWithBackend,
  ]); */

  useEffect(() => {
    if (
      // state.serverIsInitialised &&
      !state.serverIsAuthenticated &&
      user?.accessToken &&
      isAuthenticated &&
      settings.isAxiosSetup
    ) {
      // console.log("Starting signInWithServer");
      signInWithServer(user?.accessToken);
      return;
    }
  }, [
    isAuthenticated,
    signInWithServer,
    state.serverIsAuthenticated,
    user?.accessToken,
    settings.isAxiosSetup,
  ]);

  if (!state.serverIsInitialised && isAuthenticated) {
    return <MatxLoading />;
  }

  return (
    <ServerContext.Provider
      value={{
        ...state,
        method: "NODEJS",
        signInWithServer,
        clear,
        updateUserTokenOnServer,
        logoutServerCB,
      }}
    >
      {children}
    </ServerContext.Provider>
  );
};

export default ServerContext;
