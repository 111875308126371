/* CHAT THREADS */
import { ACTION_TYPES } from "../actions/ActionTypes";

export const initialChatInitializationState = {
  // Chronological order of initialization:
  userInitialized: false,
  currentChatRoomInitialized: false,
  allNerdsListFetched: false,
  newServerChatInitialized: false,
  messageListInitialized: false,
  threadsListInitialized: false,
  participantsListReady: false,
  chatRoomInitialized: false,
  chatInitialized: false, // Final step
};

export const chatThreadsReducer = (state, action) => {
  let actionMatched = true; // Default to true

  const newState = (() => {
    switch (action.type) {
      case ACTION_TYPES.SET_CHAT_INITIALIZED:
        return { ...state, chatInitialized: action.payload };
      case ACTION_TYPES.CHAT_INIT_SET_CURRENT_CHAT_ROOM_INITIALIZED:
        return { ...state, currentChatRoomInitialized: action.payload };
      case ACTION_TYPES.CHAT_INIT_MESSAGE_LIST_INITIALIZED:
        return { ...state, messageListInitialized: action.payload };
      case ACTION_TYPES.CHAT_INIT_NEW_SERVER_CHAT_INITIALIZED:
        return { ...state, newServerChatInitialized: action.payload };
      case ACTION_TYPES.CHAT_INIT_THREADS_LIST_INITIALIZED:
        return { ...state, threadsListInitialized: action.payload };
      case ACTION_TYPES.CHAT_INIT_SET_GET_ALL_NERDS_INITIALIZED:
        return { ...state, allNerdsListFetched: action.payload };
      case ACTION_TYPES.CHAT_INIT_SET_USER_INITIALIZED:
        return { ...state, userInitialized: action.payload };
      case ACTION_TYPES.CHAT_INIT_SET_PARTICIPANTS_LIST_READY:
        return { ...state, participantsListReady: action.payload };
      case ACTION_TYPES.CHAT_INIT_SET_CHAT_ROOM_INITIALIZED:
        return { ...state, chatRoomInitialized: action.payload };
      case ACTION_TYPES.RESET_CHAT_INIT:
        return initialChatInitializationState;
      case ACTION_TYPES.CHAT_INIT_START_NEW_CHAT:
        return {
          ...state,
          currentChatRoomInitialized: false,
          newServerChatInitialized: false,
          messageListInitialized: false,
          threadsListInitialized: false,
          participantsListReady: false,
          chatRoomInitialized: false,
          chatInitialized: false,
        };
      default:
        actionMatched = false;
        return { ...state };
    }
  })();

  return [newState, actionMatched];
};

export const chatInitializationReducerFetch = (state, action) => {
  const [newState, actionWasHandled] = chatThreadsReducer(state, action);
  if (actionWasHandled) {
    // console.log("chatInitializationReducerFetch | action:", action);
    /* console.log("------------------------");
    console.log("chatInitializationReducerFetch | action:", action);
    console.log("chatInitializationReducerFetch | state:", state);
    console.log("chatInitializationReducerFetch | newState:", newState);
    console.log("------------------------"); */
  }
  return { newStateForKey: newState, actionWasHandled };
};
