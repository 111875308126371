import { lazy } from "react";
import Loadable from "app/components/Loadable";

const Marketplace = Loadable(lazy(() => import("./marketplace/Marketplace")));
const Nerds = Loadable(lazy(() => import("./list/NerdsList")));
const NerdProfile = Loadable(lazy(() => import("./profile/UserProfile")));

const todoRoutes = [
  { path: "/nerds/marketplace", element: <Marketplace /> },
  { path: "/nerds/list", element: <Nerds /> },
  { path: "/nerds/profile/:id", element: <NerdProfile /> },
];

export default todoRoutes;
