import React, { useContext, useState, useEffect } from "react";
import { Grid, Button, Tooltip, Box, Icon, styled } from "@mui/material";
import { NerdSelectContext } from "./NerdSelectContext";
import { MainNerdsContext } from "app/contexts/nerds/MainNerdsContext";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

const UserName = styled("h5")(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: 0,
  fontSize: "18px",
  fontWeight: "500",
  whiteSpace: "pre",
  textAlign: "center",
}));

const NerdItemView = ({ contact }) => {
  if (!contact || !contact.name) {
    return (
      <Box
        sx={{
          maxWidth: "300px",
        }}
      >
        <UserName>Loading</UserName>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: "300px",
      }}
    >
      {contact?.avatarUrl && (
        <img
          src={contact.avatarUrl}
          alt="Avatar"
          style={{
            width: "100%",
            height: "auto",
            transition: "opacity 0.3s",
            borderRadius: "6%",
          }}
        />
      )}
      <Box sx={{ mt: 1 }}>
        <UserName>{contact.name}</UserName>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            toast.info("Adding Nerd to thread...");
          }}
          sx={{
            width: "100%",
            mt: 1,
            mb: 0,
            color: "#fff",
            textTransform: "uppercase",
          }}
        >
          Add to Thread
        </Button>
      </Box>
    </Box>
  );
};

const NerdSelectView = ({ participantsList }) => {
  const theme = useTheme();

  const {
    reset,
    participantsList: participantsListInContext,
    setParticipantsListHandler,
  } = useContext(NerdSelectContext);
  const { getNerds, contactList } = useContext(MainNerdsContext);

  useEffect(() => {
    if (participantsListInContext !== participantsList) {
      setParticipantsListHandler(participantsList);
    }
  }, [participantsList, participantsListInContext, setParticipantsListHandler]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <>
          {!contactList ||
            (contactList.length === 0 && (
              <Box
                sx={{
                  maxWidth: "300px",
                }}
              >
                <UserName>Loading</UserName>
              </Box>
            ))}
          {contactList && contactList.length > 0 && (
            <Grid container spacing={4}>
              {contactList.map((contact) => (
                <Grid item xs={12} md={4} key={contact.id}>
                  <NerdItemView contact={contact} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      </Grid>
    </Grid>
  );
};

export default NerdSelectView;
