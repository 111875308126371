import React, { useEffect } from "react";
import useSettings from "app/hooks/useSettings";
import MatxSuspense from "../MatxSuspense";
import { MatxLayouts } from "./index";
// import useServer from "app/hooks/useServer";

const MatxLayout = (props) => {
  const { settings } = useSettings();
  const Layout = MatxLayouts[settings.activeLayout];

  return (
    <MatxSuspense>
      <Layout {...props} />
    </MatxSuspense>
  );
};

export default MatxLayout;
