import React from "react";
import NerdCreateMain from "app/views/modals/nerds/createNerd/NerdCreateMain";
import NerdSelectMain from "app/views/modals/nerds/selectNerd/NerdSelectMain";

const Modals = () => {
  return (
    <>
      <NerdCreateMain />;
      <NerdSelectMain />;
    </>
  );
};

export default Modals;
