import React from "react";
import { NerdSelectProvider } from "./NerdSelectContext";
import NerdSelectView from "./NerdSelectView";
import NerdSelectModal from "./NerdSelectModal";

const NerdSelectMain = ({ participantsList }) => {
  return (
    <NerdSelectProvider>
      <NerdSelectModal>
        <NerdSelectView participantsList={participantsList} />
      </NerdSelectModal>
    </NerdSelectProvider>
  );
};

export default NerdSelectMain;
