import React, { useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { NerdCreateContext } from "./NerdCreateContext";
import { MainNerdsContext } from "app/contexts/nerds/MainNerdsContext";
import CustomizedSteppers from "app/components/stepper/Stepper";

const NerdCreateModal = (props) => {
  const { open, children } = props;
  const {
    handleSubmit,
    stepperStep,
    setStepperStep,
    reset: resetNerdCreation,
    stepsValidation,
  } = useContext(NerdCreateContext);
  const { modalOpen, onModalClose } = useContext(MainNerdsContext);

  const modalConfig = {
    title: "Create Your Nerd Avatar",
    showTitle: false,
    showStepper: true,
    submitButton: "Create Nerd",
  };
  const stepperConfig = {
    style: {
      type: "clean",
    },
    steps: ["Create Avatar", "Assign Skills", "Checkout"],
  };

  useEffect(() => {
    return () => {
      console.log(
        "Dispatching reset due to unmount of modal (NerdCreateModal.jsx)"
      );
      resetNerdCreation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={modalOpen}
      onClose={() => {
        onModalClose(false);
        resetNerdCreation();
      }}
      maxWidth="md"
      fullWidth
    >
      {modalConfig.showStepper && (
        <DialogTitle>
          <CustomizedSteppers
            config={stepperConfig}
            step={stepperStep}
            setStep={setStepperStep}
          />
        </DialogTitle>
      )}
      {modalConfig.showTitle && <DialogTitle>{modalConfig.title}</DialogTitle>}

      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          borderTop: "1px solid #777",
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        {/* make a nicer set of buttons below, using MUI styling */}
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            // Handle cancel logic
            onModalClose(false);
            resetNerdCreation();
          }}
        >
          Cancel
        </Button>

        <Box sx={{ display: "flex", gap: "8px" }}>
          {stepperStep > 0 && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setStepperStep(stepperStep - 1);
              }}
            >
              Back
            </Button>
          )}
          {stepperStep < 3 && (
            <Button
              variant="contained"
              color="primary"
              disabled={!stepsValidation[stepperStep].isValid}
              onClick={() => {
                setStepperStep(stepperStep + 1);
              }}
            >
              Next
            </Button>
          )}
          {stepperStep === 3 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit();
                resetNerdCreation();
              }}
            >
              Complete
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NerdCreateModal;
