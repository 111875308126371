import React, { useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { NerdSelectContext } from "./NerdSelectContext";
import { MainNerdsContext } from "app/contexts/nerds/MainNerdsContext";

const NerdSelectModal = (props) => {
  const { children } = props;
  const { handleSubmit } = useContext(NerdSelectContext);
  // const { modalOpen, onModalClose } = useContext(MainNerdsContext);
  const { selectNerdModalOpen, setSelectNerdModalOpen } =
    useContext(MainNerdsContext);

  return (
    <Dialog
      open={selectNerdModalOpen}
      onClose={() => setSelectNerdModalOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Select Your Nerd Avatar</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #777" }}>
        {/* make a nicer set of buttons below, using MUI styling */}
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            // Handle cancel logic
            setSelectNerdModalOpen(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NerdSelectModal;
