import { lazy } from "react";
import Loadable from "app/components/Loadable";

const Writer = Loadable(lazy(() => import("./writer/Writer")));
const CreativeTypesMain = Loadable(lazy(() => import("./creative-types/CreativeTypesMain")));
const MarketingOffers = Loadable(lazy(() => import("./marketing-offers/MarketingOffers")));
// const TodoEditor = Loadable(lazy(() => import("./TodoEditor")));

const todoRoutes = [
  { path: "/copy/writer", element: <Writer /> },
  { path: "/copy/creative-types", element: <CreativeTypesMain /> },
  { path: "/copy/marketing-offers", element: <MarketingOffers /> },
  /* { path: "/todo/list/:id", element: <TodoEditor /> }, */
];

export default todoRoutes;
