/* CHAT PARTICIPANTS */

import { ACTION_TYPES } from "../actions/ActionTypes";

export const initialChatParticipantsState = {
  participantsList: [],
  allNerdsList: [],
  currentUser: null,
};

export const chatParticipantsReducer = (state, action) => {
  let actionMatched = true; // Default to true

  const newState = (() => {
    switch (action.type) {
      case ACTION_TYPES.SET_PARTICIPANTS_LIST:
        return { ...state, participantsList: action.payload };
      case ACTION_TYPES.SET_ALL_NERDS_LIST:
        return { ...state, allNerdsList: action.payload };
      case ACTION_TYPES.SET_CURRENT_USER:
        return { ...state, currentUser: action.payload };
      case ACTION_TYPES.RESET_CHAT_PARTICIPANTS:
        return initialChatParticipantsState;
      default:
        actionMatched = false;
        return { ...state };
    }
  })();

  return [newState, actionMatched];
};

export const chatParticipantsReducerFetch = (state, action) => {
  const [newState, actionWasHandled] = chatParticipantsReducer(state, action);
  if (actionWasHandled) {
    // console.log("chatParticipantsReducerFetch | action:", action);
    /* console.log("--------------------------------------------");
    console.log("chatParticipantsReducerFetch | action:", action);
    console.log("chatParticipantsReducerFetch | (BEFORE) state:", state);
    console.log("chatParticipantsReducerFetch | (AFTER) newState:", newState);
    console.log("--------------------------------------------"); */
  }
  return { newStateForKey: newState, actionWasHandled };
};
