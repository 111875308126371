/* CHAT THREADS */
import { ACTION_TYPES } from "../actions/ActionTypes";

export const initialChatThreadsState = {
  threadsList: [],
  filteredThreadsList: [],
  threadsListLoaded: false,
  generateChatRoom: {
    chatName: "",
    meta_description: "",
    state: "init",
  },
  searchThreadsList: "",
  triggerUpdateChatThreadsList: false,
  triggerStartNewChat: false,
  chatIdToDelete: null,
  triggerScrollToTopOfThreadsList: false,
  generateNewThreadPending: false,
};

export const chatThreadsReducer = (state, action) => {
  let actionMatched = true; // Default to true

  const newState = (() => {
    /* if (action.type === ACTION_TYPES.RESET_ALL) {
      // Maybe do some special chat-specific reset logic here
      return initialChatThreadsState;
    } */

    switch (action.type) {
      case ACTION_TYPES.SET_THREADS_LIST:
        return {
          ...state,
          threadsList: action.payload,
          threadsListLoaded: true,
        };
      case ACTION_TYPES.SET_THREADS_LIST_LOADED:
        return { ...state, threadsListLoaded: action.payload };
      case ACTION_TYPES.SET_FILTERED_THREADS_LIST:
        return { ...state, filteredThreadsList: action.payload };
      case ACTION_TYPES.GENERATE_CHAT_NAME:
        return {
          ...state,
          generateChatRoom: {
            chatName: action.payload.chatName,
            meta_description: action.payload.meta_description,
            state: action.payload.state || state.generateChatRoom.state,
          },
        };
      case ACTION_TYPES.GENERATE_CHAT_NAME_UPDATE_STATE:
        return {
          ...state,
          generateChatRoom: {
            ...state.generateChatRoom,
            state: action.payload,
          },
        };
      case ACTION_TYPES.SET_SEARCH_THREADS_LIST:
        return { ...state, searchThreadsList: action.payload };
      case ACTION_TYPES.SET_TRIGGER_UPDATE_CHAT_THREADS_LIST:
        return { ...state, triggerUpdateChatThreadsList: action.payload };
      case ACTION_TYPES.DELETE_CHAT_BY_ID:
        return {
          ...state,
          chatIdToDelete: action.payload.chatIdToDelete,
        };
      case ACTION_TYPES.SET_CLEAR_SEARCH_THREADS_LIST:
        return { ...state, searchThreadsList: "" };
      case ACTION_TYPES.RESET_CHAT_THREADS:
        return initialChatThreadsState;
      case ACTION_TYPES.TRIGGER_START_NEW_CHAT:
        return { ...state, triggerStartNewChat: action.payload };
      case ACTION_TYPES.SET_TRIGGER_SCROLL_THREADS_LIST:
        return { ...state, triggerScrollToTopOfThreadsList: action.payload };
      case ACTION_TYPES.CREATE_NEW_THREAD_PENDING:
        return {
          ...state,
          generateNewThreadPending: action.payload,
        };
      default:
        actionMatched = false;
        return { ...state };
    }
  })();

  return [newState, actionMatched];
};

export const chatThreadsReducerFetch = (state, action) => {
  const [newState, actionWasHandled] = chatThreadsReducer(state, action);
  if (actionWasHandled) {
    // console.log("chatThreadsReducerFetch | action:", action);
    /* console.log("------------------------");
    console.log("chatThreadsReducerFetch | action:", action);
    console.log("chatThreadsReducerFetch | state:", state);
    console.log("chatThreadsReducerFetch | newState:", newState);
    console.log("------------------------"); */
  }
  return { newStateForKey: newState, actionWasHandled };
};
