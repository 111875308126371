import { useEffect, useContext } from "react";
import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";
import { useChatThreadsCallBacks } from "app/contexts/chat/callbacks/ChatThreadsCallBacks";
import { useChatInitCallBacks } from "app/contexts/chat/callbacks/ChatInitCallBacks";
import { useChatRoomCallBacks } from "app/contexts/chat/callbacks/ChatRoomCallBacks";
import useMainAuth from "app/hooks/useMainAuth";
import { toast } from "react-toastify";
import MainAuthContext from "app/contexts/MainAuthContext";

import { getThreadList, getListOfNerdsByIds } from "app/services/ChatService";

export const useChatInitEffects = (state, dispatch, globalState) => {
  // const { socketRef } = useRefs();
  const { getAllNerdsUpdate } = useChatInitCallBacks(globalState, dispatch);
  const { updateCurrentChatRoom } = useChatRoomCallBacks(globalState, dispatch);
  const { startNewChatThreadHandler, findNewThread } = useChatThreadsCallBacks(
    globalState,
    dispatch
  );

  const { firebase, nodeServer, isInitialised } = useContext(MainAuthContext);

  /**
   * Set current User (serverUser and user) via nodeServer and firebase from useMainAuth
   * Triggered by: None
   * Requires: nodeServer, firebase
   * Sets: currentUser && userInitialized
   */
  useEffect(() => {
    if (
      !state.chatInitialized &&
      !state.userInitialized &&
      isInitialised &&
      firebase.isAuthenticated &&
      nodeServer.isAuthenticated
    ) {
      // console.log("useChatInitEffects | SEQUENCE: START");

      const currentUser = {
        serverUser: null,
        user: null,
      };

      if (nodeServer) {
        // console.log("nodeServer: ", nodeServer);
        const { serverUser } = nodeServer;
        if (serverUser) {
          currentUser.serverUser = serverUser;
        }
      }

      if (firebase) {
        // console.log("firebase: ", firebase);
        const { user } = firebase;
        if (user) {
          currentUser.user = user;
        }
      }

      if (JSON.stringify(currentUser) !== JSON.stringify(state.currentUser)) {
        dispatch({
          type: ACTION_TYPES.SET_CURRENT_USER,
          payload: currentUser,
        });
        dispatch({
          type: ACTION_TYPES.CHAT_INIT_SET_USER_INITIALIZED,
          payload: true,
        });
      }
    }
  }, [
    firebase,
    nodeServer,
    state.currentUser,
    dispatch,
    state.chatInitialized,
    state.getAllNerdsInitialized,
    state.user,
    state.userInitialized,
    isInitialised,
  ]);

  /**
   * Get list of All Nerds when currentChatRoom changes
   * Triggered by: userInitialized
   * Requires: Nothing
   * Sets: Nerdslist
   */
  useEffect(() => {
    // Requires: Nothing
    if (
      !state.chatInitialized &&
      !state.getAllNerdsInitialized &&
      state.userInitialized &&
      !state.allNerdsListFetched
    ) {
      // console.log("useChatInitEffects | SEQUENCE: 1");

      getAllNerdsUpdate()
        .then((data) => {
          // console.log("getAllNerdsUpdate | data: ", data);
          dispatch({
            type: ACTION_TYPES.CHAT_INIT_SET_GET_ALL_NERDS_INITIALIZED,
            payload: true,
          });
        })
        .catch((error) => {
          console.error("getAllNerdsUpdate | error: ", error);
          toast.error("Error getting nerds list");
        });
    }
  }, [
    dispatch,
    getAllNerdsUpdate,
    state.getAllNerdsInitialized,
    state.chatInitialized,
    state.userInitialized,
    state.allNerdsListFetched,
  ]);

  /**
   * Get Threads List after starting new chat
   * Triggered by: newServerChatInitialized
   * Requires: chatRoom.oneOnOneWithNerdId
   * Sets: threadsListInitialized
   */
  useEffect(() => {
    if (
      !state.chatInitialized &&
      globalState?.chatRoom?.oneOnOneWithNerdId &&
      !state.threadsListInitialized
    ) {
      // console.log("useChatInitEffects | SEQUENCE: 2");
      getThreadList(globalState.chatRoom.oneOnOneWithNerdId)
        .then((data) => {
          if (data.status === 200) {
            dispatch({ type: "SET_THREADS_LIST", payload: data.data });
            dispatch({
              type: ACTION_TYPES.CHAT_INIT_THREADS_LIST_INITIALIZED,
              payload: true,
            });
          } else {
            toast.error("Error getting threads list");
          }
        })
        .catch((e) => {
          console.error("getThreadList | Error: ", e);
          toast.error("Error getting threads list");
        });
    }
  }, [
    state.newServerChatInitialized,
    globalState?.chatRoom?.oneOnOneWithNerdId,
    state.chatInitialized,
    dispatch,
    state.threadsListInitialized,
  ]);

  /**
   * Start a new Chat on the Server. Gets back a chatId.
   * Triggered by: threadsListInitialized
   * Requires: Optional[chatRoom.oneOnOneWithNerdId],
   * Sets: currentChatRoomInitialized, messageListInitialized & newServerChatInitialized
   *
   * Note: We want to check if there is an a new *(means no name) thread. If yes, we use that one, else we create a new one.
   */
  useEffect(() => {
    if (
      !state.chatInitialized &&
      !state.newServerChatInitialized &&
      state.threadsListInitialized &&
      globalState?.chatRoom?.oneOnOneWithNerdId
    ) {
      // console.log("useChatInitEffects | SEQUENCE: 3");
      const existingNewThread = findNewThread();

      if (existingNewThread) {
        // console.log("existingNewThread: ", existingNewThread);
        dispatch({
          type: ACTION_TYPES.SET_CURRENT_CHAT_ROOM,
          payload: existingNewThread,
        });
        dispatch({
          type: ACTION_TYPES.CHAT_INIT_SET_CURRENT_CHAT_ROOM_INITIALIZED,
          payload: true,
        });

        dispatch({
          type: ACTION_TYPES.SET_MESSAGE_LIST,
          payload: existingNewThread.messages || [],
        });
        dispatch({
          type: ACTION_TYPES.CHAT_INIT_MESSAGE_LIST_INITIALIZED,
          payload: true,
        });

        dispatch({
          type: ACTION_TYPES.SET_TRIGGER_SCROLL,
          payload: true,
        });

        dispatch({
          type: ACTION_TYPES.CHAT_INIT_NEW_SERVER_CHAT_INITIALIZED,
          payload: true,
        });

        return;
      }

      startNewChatThreadHandler()
        .then((data) => {
          // Mark Chat Room as initialized
          dispatch({
            type: ACTION_TYPES.CHAT_INIT_SET_CURRENT_CHAT_ROOM_INITIALIZED,
            payload: true,
          });
          // Mark Messages as initialized
          dispatch({
            type: ACTION_TYPES.CHAT_INIT_MESSAGE_LIST_INITIALIZED,
            payload: true,
          });
          dispatch({
            type: ACTION_TYPES.CHAT_INIT_NEW_SERVER_CHAT_INITIALIZED,
            payload: true,
          });
        })
        .catch((e) => {
          console.error("startNewChatThreadHandler | Error: ", e);
          toast.error("Error starting new chat");
        });
    }
  }, [
    dispatch,
    globalState.chatRoom.oneOnOneWithNerdId,
    state.chatInitialized,
    startNewChatThreadHandler,
    state.newServerChatInitialized,
    state.threadsListInitialized,
    findNewThread,
  ]);

  /**
   * Get participants data from API when currentChatRoom changes
   * Triggered by: currentChatRoomInitialized
   * Requires: currentChatRoom
   * Sets: participantsList & participantsListReady
   */
  useEffect(() => {
    if (
      !state.participantsListReady &&
      state.newServerChatInitialized &&
      state.messageListInitialized &&
      state.currentChatRoomInitialized
    ) {
      // console.log("useChatInitEffects | SEQUENCE: 4");
      if (state.currentChatRoomInitialized && !state.participantsListReady) {
        const { participants } = globalState?.chatRoom?.currentChatRoom;
        if (participants && participants.length > 0) {
          const nerdIds =
            participants?.map((participant) => {
              return { nerdId: participant.nerdId };
            }) || [];

          getListOfNerdsByIds({ nerdIds }).then(({ data }) => {
            if (data && data.length > 0) {
              dispatch({
                type: "SET_PARTICIPANTS_LIST",
                payload: data,
              });
            }
            if (data && data.length === 0) {
              dispatch({
                type: "SET_PARTICIPANTS_LIST",
                payload: [],
              });
            }

            dispatch({
              type: ACTION_TYPES.CHAT_INIT_SET_PARTICIPANTS_LIST_READY,
              payload: true,
            });
          });
        }
      }
    }
  }, [
    dispatch,
    globalState.chatInitialization.chatInitialized,
    globalState?.chatRoom?.currentChatRoom,
    state.currentChatRoom,
    state.currentChatRoomInitialized,
    state.messageListInitialized,
    state.newServerChatInitialized,
    state.participantsListReady,
  ]);

  /**
   * Update currentChatRoom Triggered by dispatched action
   * THIS **EXECUTES** THE LOADING OF CONTENT IN THE CHAT ROOM
   * Triggered by: participantsListReady
   *
   */
  useEffect(() => {
    if (state.participantsListReady && !state.chatRoomInitialized) {
      // console.log("useChatInitEffects | SEQUENCE: 5");
      const chatId = globalState?.chatRoom?.currentChatRoom?.chatId || null;
      if (!chatId) {
        return false;
      }
      updateCurrentChatRoom({
        chatId,
      });
      dispatch({
        type: "CHAT_INIT_SET_CHAT_ROOM_INITIALIZED",
        payload: true,
      });
    }
  }, [
    dispatch,
    globalState.chatInitialization.chatInitialized,
    globalState?.chatRoom?.currentChatRoom?.chatId,
    state,
    state.currentChatRoom,
    state.intialMessageListFetchCompleted,
    state.triggerUpdateCurrentChatRoom,
    state.triggerUpdateCurrentChatRoomByChatId,
    updateCurrentChatRoom,
  ]);

  /**
   * Final step in chat initialization.
   * Triggered by: all previous steps
   * Requires: Not relevant
   * Sets: chatInitialized
   */
  useEffect(() => {
    if (
      !state.chatInitialized &&
      state.currentChatRoomInitialized &&
      state.allNerdsListFetched &&
      state.newServerChatInitialized &&
      state.messageListInitialized &&
      state.threadsListInitialized &&
      state.participantsListReady &&
      state.chatRoomInitialized &&
      state.userInitialized
    ) {
      // console.log("useChatInitEffects | SEQUENCE: 6 ( END ) ");
      dispatch({
        type: ACTION_TYPES.SET_CHAT_INITIALIZED,
        payload: true,
      });
    }
  }, [
    state.currentChatRoomInitialized,
    state.chatInitialized,
    state.allNerdsListFetched,
    state.newServerChatInitialized,
    state.messageListInitialized,
    state.threadsListInitialized,
    dispatch,
    state.userInitialized,
    state.participantsListReady,
    state.chatRoomInitialized,
  ]);
};
