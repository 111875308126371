import { createContext, useReducer, useContext, useState } from "react";
import { hireNerd } from "app/services/nerdServices";
import { toast } from "react-toastify";
import { MainNerdsContext } from "app/contexts/nerds/MainNerdsContext";
import { MainChatContext } from "app/contexts/chat/MainChatContext";

import { useEffect } from "react";

const NerdSelectContext = createContext();

const initialState = {
  allFilteredNerds: [],
  allNerdsFetched: false,
};

const reducer = (state, action) => {
  console.log("action:", action);
  // console.log("state:", state);
  switch (action.type) {
    case "SET_ALL_NERDS":
      return { ...state, allNerds: action.payload };
    case "SET_ALL_NERDS_FETCHED":
      return { ...state, allNerdsFetched: action.payload };
    case "RESET":
      return initialState;
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const NerdSelectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { contactList } = useContext(MainNerdsContext);
  const [participantsList, setParticipantsList] = useState([]);

  const setParticipantsListHandler = (participants) => {
    setParticipantsList(participants);
  };

  useEffect(() => {
    // Participants are already in the chat.
    // contactList are all nerds.
    if (
      contactList &&
      contactList.length > 0 &&
      participantsList &&
      participantsList.length > 0
    ) {
      // Filter out any nerds that are already in the chat.
      /* console.log("contactList:", contactList);
      console.log("participantsList:", participantsList); */

      const filteredContactList = contactList?.filter((contact) => {
        return !participantsList?.some((participant) => {
          return participant.userId === contact.nerdId;
        });
      });
      if (filteredContactList.length === 0) {
        toast.info("All nerds are already in the chat");
        return;
      }

      // console.log("filteredContactList:", filteredContactList);

      dispatch({ type: "SET_ALL_NERDS", payload: filteredContactList });
    }
  }, [contactList, participantsList]);

  return (
    <NerdSelectContext.Provider
      value={{
        ...state,
        // getAllNerdsHandler,
        reset: () => dispatch({ type: "RESET" }),
        setParticipantsListHandler,
      }}
    >
      {children}
    </NerdSelectContext.Provider>
  );
};

export { NerdSelectProvider, NerdSelectContext };
