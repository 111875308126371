// Reducers.jsx - This is where we combine all the reducers into one

import {
  chatThreadsReducerFetch,
  initialChatThreadsState,
} from "./chatThreadsReducer";

import {
  chatMessagesReducerFetch,
  initialChatMessagesState,
} from "./chatMessagesReducer";

import { chatRoomReducerFetch, initialChatRoomState } from "./chatRoomReducer";

import {
  chatSocketReducerFetch,
  initialChatSocketState,
} from "./chatSocketReducer";

import {
  chatParticipantsReducerFetch,
  initialChatParticipantsState,
} from "./chatParticipantsReducer";

import {
  chatInitializationReducerFetch,
  initialChatInitializationState,
} from "./chatInitializationReducer";

export const REDUCERS = {
  chatRoom: chatRoomReducerFetch,
  chatThreads: chatThreadsReducerFetch,
  chatMessages: chatMessagesReducerFetch,
  chatSocket: chatSocketReducerFetch,
  chatParticipants: chatParticipantsReducerFetch,
  chatInitialization: chatInitializationReducerFetch,
};

const INITIAL_STATE = {
  chatRoom: initialChatRoomState,
  chatThreads: initialChatThreadsState,
  chatMessages: initialChatMessagesState,
  chatSocket: initialChatSocketState,
  chatParticipants: initialChatParticipantsState,
  chatInitialization: initialChatInitializationState,
};

console.log("INITIAL_STATE:", INITIAL_STATE);

export { INITIAL_STATE };
