import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Grid,
  Button,
  Tooltip,
  Box,
  Icon,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Form,
  Schema,
  Input,
  SelectPicker,
  Uploader,
  Whisper,
  Radio,
  RadioGroup,
} from "rsuite";
import { NerdCreateContext } from "./NerdCreateContext";
import {
  generateAvatarImage,
  generateAvatarName,
} from "app/services/nerdServices";
import { toast } from "react-toastify";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { useCallback } from "react";
import { useSortedNationalitiesListList } from "app/utils/Utils";
import UploadBase64Image from "app/components/uploaders/ImageUploader";

const StyledHeader = styled("h2")(({ theme }) => ({
  color: theme.palette.text.primary,
  // fontSize: "1.5rem",

  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "0.1rem",
  textAlign: "center",
  margin: "0",
  padding: "0",
  fontFamily: "'Poiret One', cursive",
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
}));

const NerdCreateView = ({ nerdTemplate }) => {
  const {
    setAvatarURL,
    selectedGender,
    setSelectedGender,
    useUploader,
    setUseUploader,

    formErrors,
    setFormErrors,
    formValues,
    setFormValues,

    resetForm,
    formVisible,
    setFormVisible,
    formPristine,

    formValid,
    setFormValid,

    getAvatarImage,

    avatarThemeSelect,
    avatarThemeSelectOptions,
    setAvatarThemeSelect,

    stepsValidation,
    setStepsValidation,

    avatarURL,
    suggestedNames,
    setSuggestedNames,

    nameSuggestionLimit,
    nameSuggestionCounter,
    updateNameSuggestionCounter,

    defaultAvatarURLs,

    setNationality,
    nationalityChoise,

    avatarAge,
    updateAvatarAge,
  } = useContext(NerdCreateContext);

  const targetAvatarImageRef = useRef(null);

  const theme = useTheme();
  const [generatingName, setGeneratingName] = useState(false);
  const [avatarImageInUse, setAvatarImageInUse] = useState(false);
  const [sortedNationalities, setSortedNationalities] = useState(
    useSortedNationalitiesListList() || []
  );

  const scrollToElement = (ref) => {
    const element = ref.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const avatarImage =
      avatarURL ||
      defaultAvatarURLs?.[selectedGender] ||
      "/assets/images/avatars/default_bot.svg";

    if (!avatarImage) return;

    if (avatarImage !== avatarImageInUse) setAvatarImageInUse(avatarImage);
  }, [avatarImageInUse, avatarURL, defaultAvatarURLs, selectedGender]);

  const setFormValuesHandler = useCallback(
    (value) => {
      // console.log("setFormValuesHandler: ", value);
      setFormValues(value);
    },
    [setFormValues]
  );

  const handleGenderChange = (value) => {
    setSelectedGender(value);
    // TODO: Handle avatar theme change logic based on avatarTheme state
  };

  const checkAvatarURL = (base64) => {
    // check if base64 is valid
    const base64ImagePattern = /^data:image\/[a-zA-Z]+;base64,[^\s]*$/;
    if (!base64ImagePattern.test(base64)) {
      toast.error("Error generating avatar, image is not valid.");
      return false;
    }
    return true;
  };

  const [avatarGenerationLoading, setAvatarGenerationLoading] = useState(false);

  const handleGenerateAvatar = async () => {
    try {
      if (avatarGenerationLoading)
        return toast.warn("Please wait for the avatar to generate.");

      setAvatarGenerationLoading(true);

      const config = {
        diversity:
          formValues.diversity.length > 0
            ? formValues.diversity
            : selectedGender,
        gender: selectedGender,
        model: avatarThemeSelect,
        nationality: sortedNationalities.find(
          (nat) => nat.alpha_3_code === nationalityChoise
        ).nationality,
        age: avatarAge,
        modelStyle: avatarThemeSelectOptions.find(
          (theme) => theme.value === avatarThemeSelect
        ).label,
        country: sortedNationalities.find(
          (nat) => nat.alpha_3_code === nationalityChoise
        ).en_short_name,
      };

      console.log("handleGenerateAvatar | config: ", config);

      const newAvatarResponse = await generateAvatarImage(config);
      if (!newAvatarResponse) {
        throw new Error("Error generating avatar #1.");
      }

      const newAvatarBase64 = newAvatarResponse?.[0]; // Expect one image in an array

      const avatarValid = checkAvatarURL(newAvatarBase64);
      if (!avatarValid) {
        throw new Error("Error generating avatar #2.");
      }

      setAvatarURL(newAvatarBase64);
      setAvatarGenerationLoading(false);
      scrollToElement(targetAvatarImageRef);
    } catch (error) {
      console.error("Error generating avatar: ", error);
      toast.error("Error generating avatar.");
      setAvatarGenerationLoading(false);
    }
  };

  const onUserImageUpload = (base64) => {
    if (avatarGenerationLoading)
      return toast.warn("Please wait for the avatar to generate.");

    try {
      setAvatarGenerationLoading(true);
      setAvatarURL(base64);

      const avatarValid = checkAvatarURL(base64);

      if (!avatarValid) {
        throw new Error("Error generating avatar #2.");
      }

      setAvatarURL(base64);
      setAvatarGenerationLoading(false);
      setUseUploader(false);
      scrollToElement(targetAvatarImageRef);
    } catch (error) {
      console.error("Error uploading avatar: ", error);
      toast.error("Error uploading avatar.");
      setAvatarGenerationLoading(false);
    }
  };

  const handleGenerateAvatarName = async () => {
    try {
      if (suggestedNames.length === 0) {
        if (generatingName) {
          toast.warn("Please wait for the name to generate.");
          return;
        }

        if (nameSuggestionCounter >= nameSuggestionLimit) {
          toast.error("You have reached the limit of name suggestions.");
          return;
        }

        // update nameSuggestionCounter
        updateNameSuggestionCounter();

        // Set loading to ON.
        setGeneratingName(true);

        const config = {
          diversity:
            formValues.diversity.length > 0
              ? formValues.diversity
              : selectedGender,
          gender: selectedGender,
          nationality: sortedNationalities.find(
            (nat) => nat.alpha_3_code === nationalityChoise
          ).nationality,
          age: avatarAge,
          modelStyle: avatarThemeSelectOptions.find(
            (theme) => theme.value === avatarThemeSelect
          ).label,
          country: sortedNationalities.find(
            (nat) => nat.alpha_3_code === nationalityChoise
          ).en_short_name,
        };

        // Generate a new set of names.
        const newAvatarNames = await generateAvatarName(config);

        if (!newAvatarNames || newAvatarNames.length === 0) {
          throw new Error("Error generating avatar name.");
        }

        setFormValuesHandler({ ...formValues, name: newAvatarNames.pop() }); // Use the first Name provided.

        // Store the rest of the names in the suggestedNames array.
        if (newAvatarNames.length > 0) {
          setSuggestedNames(newAvatarNames);
        }

        setGeneratingName(false);
      } else {
        // Pop a name from suggestedNames and use it, then update suggestedNames to remove the name used.
        const newAvatarName = suggestedNames.pop();
        setFormValuesHandler({ ...formValues, name: newAvatarName });
        setSuggestedNames(suggestedNames);
      }
    } catch (error) {
      console.log("Error generating avatar name: ", error);
      toast.error("Error generating avatar.");
      setGeneratingName(false);
    }
  };

  const getSystemGeneratedDiversityDescription = (genderChoice) => {
    switch (genderChoice) {
      case "male":
        return "Male, in their 20's, random ethnicity.";
      case "female":
        return "female, in their 20's, random ethnicity.";
      case "bot":
        return "a Robot, not a human, not a cyborg.";
      default:
        return "Male, in their 20's, random ethnicity.";
    }
  };

  const { StringType, ArrayType } = Schema.Types;
  const model = Schema.Model({
    name: StringType()
      .minLength(2)
      .maxLength(60)
      .isRequired("This field is required."),
    diversity: StringType().isRequired("This field is required."),
    diversityUserDescription: StringType().minLength(2).maxLength(60),
  });

  /**
   * Update divercity form field based on gender choice and vice versa.
   * When gender choice is divercity, use the user description.
   * When gender choice is not divercity, use the system generated description.
   */
  useEffect(() => {
    if (
      selectedGender === "diversity" &&
      formValues.diversityUserDescription !== formValues.diversity
    ) {
      setFormValuesHandler({
        ...formValues,
        diversity: formValues.diversityUserDescription,
      });
    }

    const systemGeneratedDiversityDescription =
      getSystemGeneratedDiversityDescription(selectedGender);

    if (
      selectedGender !== "diversity" &&
      formValues.diversity !== systemGeneratedDiversityDescription
    ) {
      setFormValuesHandler({
        ...formValues,
        diversity: systemGeneratedDiversityDescription,
      });
    }
  }, [
    formValues,
    formValues.diversityUserDescription,
    formValues.diversity,
    selectedGender,
    setFormValuesHandler,
  ]);

  useEffect(() => {
    console.log("CONTEXT stepsValidation: ", stepsValidation);
  }, [stepsValidation]);

  // Check and update Form Errors.
  useEffect(() => {
    // console.log("Checking form errors | formValues: ", formValues);
    const errors = model.check(formValues);
    // console.log("Checking form errors | errors: ", errors);

    // Only update the form errors if they actually changed
    if (JSON.stringify(errors) !== JSON.stringify(formErrors)) {
      setFormErrors(errors || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, model, formErrors]);

  const isFormValid = (errors) => {
    // If errors is null or undefined, form is valid
    if (!errors) return true;

    // Check each field's hasError property
    for (let field in errors) {
      if (errors[field].hasError) {
        return false; // Found a field with an error
      }
    }

    return true; // No fields with errors found
  };

  // Check if Form is valid.
  useEffect(() => {
    const errors = model.check(formValues);

    const newFormValid = isFormValid(errors);

    // Only update the form validity if it actually changed
    if (newFormValid !== formValid) {
      setFormValid(newFormValid);
      if (newFormValid !== stepsValidation[0].isValid) {
        setStepsValidation(0, newFormValid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, model, formValid]);

  const GenderToggle = () => {
    const theme = useTheme();

    const styles = {
      radioGroupLabel: {
        padding: "7px 2px 7px 12px",
        display: "flex",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
      },
    };

    return (
      <Form.Group
        controlId="radioList"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <RadioGroup
          name="radioList"
          inline
          appearance="picker"
          defaultValue="A"
          onChange={handleGenderChange}
          value={selectedGender}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "6px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <span style={styles.radioGroupLabel}>Gender: </span>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <Radio value="male">
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Icon>
                    <MaleIcon />
                  </Icon>
                  {selectedGender === "male" && <span>Male</span>}
                </Box>
              </Radio>
              <Radio value="female">
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Icon>
                    <FemaleIcon />
                  </Icon>
                  {selectedGender === "female" && <span>Female</span>}
                </Box>
              </Radio>
              <Radio value="diversity">
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    verticalAlign: "middle",
                    gap: "3px",
                  }}
                >
                  <Icon>
                    <Diversity1Icon />
                  </Icon>
                  {selectedGender === "diversity" && <span>Diversity</span>}
                </Box>
              </Radio>
              <Radio value="bot">
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    verticalAlign: "middle",
                    gap: "3px",
                  }}
                >
                  <Icon>
                    <SmartToyIcon />
                  </Icon>
                  {selectedGender === "bot" && <span>Bot</span>}
                </Box>
              </Radio>
            </Box>
          </Box>
        </RadioGroup>
      </Form.Group>
    );
  };

  const getAvatarThemeValue = useCallback(() => {
    /* console.log("getAvatarThemeValue: ", avatarThemeSelect);
    console.log("avatarThemeSelect.length: ", avatarThemeSelect.length);
    console.log("avatarThemeSelectOptions: ", avatarThemeSelectOptions);
    console.log(
      "avatarThemeSelectOptions?.[0]?.value : ",
      avatarThemeSelectOptions?.[0]?.value
    ); */

    const findCyberpunk = avatarThemeSelectOptions?.find(
      (item) => item.label.toLowerCase() === "cyberpunk"
    );

    // console.log("findCyberpunk: ", findCyberpunk);

    const defaultTheme = findCyberpunk
      ? findCyberpunk.value
      : avatarThemeSelectOptions?.[0]?.value;

    // console.log("defaultTheme: ", defaultTheme);

    return avatarThemeSelect.length > 0
      ? avatarThemeSelect
      : defaultTheme || "";
  }, [avatarThemeSelect, avatarThemeSelectOptions]);

  /**
   * Sets the default avatarThemeSelect value to the first option in the list if non-selected and model list is availabe.
   */
  useEffect(() => {
    if (
      avatarThemeSelect !== "" ||
      !avatarThemeSelectOptions ||
      avatarThemeSelectOptions.length === 0
    )
      return;

    const avatarThemeValue = getAvatarThemeValue();
    console.log("avatarThemeValue: ", avatarThemeValue);

    if (avatarThemeValue.length > 0) {
      setAvatarThemeSelect(avatarThemeValue);
    }
  }, [
    avatarThemeSelect,
    avatarThemeSelectOptions,
    getAvatarThemeValue,
    setAvatarThemeSelect,
  ]);

  const avatarAgeOptions = [20, 30, 40, 50, 60];

  return (
    <Grid container spacing={4}>
      {/* Left Side */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "left",
          mt: 2,
        }}
      >
        <Form
          model={model}
          onChange={setFormValuesHandler}
          sx={{ width: "100%" }}
        >
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <StyledHeader>Customize Your AI Nerd</StyledHeader>
              </Box>
            </Grid>
            {/* Gender */}
            <Grid item xs={12}>
              <Tooltip
                arrow
                title="Choose a gender for your Nerd"
                placement="top-end"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <GenderToggle sx={{ width: "100%" }} />
                </Box>
              </Tooltip>
            </Grid>
            {/* Diversity */}
            {selectedGender === "diversity" && (
              <Grid item xs={12}>
                <Tooltip
                  arrow
                  placement="top-end"
                  title="Our AI Avatar generation respects all genders, and ethnicities."
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <Input
                      name="diversityUserDescription"
                      placeholder="Describe your Nerds Gender, ethnicity, etc."
                      sx={{ width: "auto", flexGrow: 1 }}
                      onChange={(value) => {
                        setFormValuesHandler({
                          ...formValues,
                          diversityUserDescription: value,
                        });
                      }}
                    />
                  </Box>
                </Tooltip>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    width: "100%",
                    gap: "4px",
                    mt: 1,
                    pl: 2,
                  }}
                >
                  <div>{`Characters: ${formValues?.diversityUserDescription?.length}/60`}</div>
                  {!formPristine && (
                    <div style={{ color: "red" }}>
                      {formErrors.name?.errorMessage}
                    </div>
                  )}
                </Box>
              </Grid>
            )}
            {/* Nationality & Age */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "Flex",
                  gap: { xs: 3, sm: 2 },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                {/* Nationality */}
                <Tooltip
                  arrow
                  title="Where is your Nerd From?"
                  placement="top-end"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Nationality
                      </InputLabel>
                      <Select
                        labelId="avatar-nationality-select-label"
                        id="avatar-nationality-select"
                        value={nationalityChoise}
                        label="Avatar Theme"
                        style={{
                          width: "100%",
                          color: theme.palette.text.primary,
                          borderColor: theme.palette.text.primary,
                          height: "40px",

                          "&:before": {
                            borderColor: theme.palette.text.primary,
                          },
                          "&:after": {
                            borderColor: theme.palette.text.primary,
                          },
                          "&:hover:not(.MuiDisabled):before": {
                            borderColor: theme.palette.text.primary,
                          },
                          "&:hover": {
                            borderColor: theme.palette.text.primary,
                          },
                        }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNationality(event.target.value);
                        }}
                      >
                        {sortedNationalities.map((item) => (
                          <MenuItem
                            value={item.alpha_3_code}
                            key={item.nationality + "_" + item.alpha_3_code}
                          >
                            {item.nationality} ({item.alpha_3_code})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Tooltip>
                {/* Age */}
                <Tooltip
                  arrow
                  title="How old is your Nerd?"
                  placement="top-end"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Avatar Theme
                      </InputLabel>
                      <Select
                        labelId="avatar-age-select-label"
                        id="avatar-age-select"
                        value={avatarAge}
                        label="Avatar Age"
                        style={{
                          width: "100%",
                          color: theme.palette.text.primary,
                          borderColor: theme.palette.text.primary,
                          height: "40px",

                          "&:before": {
                            borderColor: theme.palette.text.primary,
                          },
                          "&:after": {
                            borderColor: theme.palette.text.primary,
                          },
                          "&:hover:not(.MuiDisabled):before": {
                            borderColor: theme.palette.text.primary,
                          },
                          "&:hover": {
                            borderColor: theme.palette.text.primary,
                          },
                        }}
                        onChange={(event) => {
                          event.preventDefault();
                          updateAvatarAge(event.target.value);
                        }}
                      >
                        {avatarAgeOptions?.map((item) => (
                          <MenuItem value={item} key={item}>
                            {item}
                            {item === 60 ? "'s +" : "'s"}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
            {/* Theme */}
            <Grid item xs={12}>
              <Tooltip
                arrow
                title="Choose a Graphic Style for your Nerd"
                placement="top-end"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Avatar Theme
                    </InputLabel>
                    <Select
                      labelId="avatar-theme-select-label"
                      id="avatar-theme-select"
                      value={avatarThemeSelect}
                      label="Avatar Theme"
                      style={{
                        width: "100%",
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.text.primary,
                        height: "40px",

                        "&:before": {
                          borderColor: theme.palette.text.primary,
                        },
                        "&:after": {
                          borderColor: theme.palette.text.primary,
                        },
                        "&:hover:not(.MuiDisabled):before": {
                          borderColor: theme.palette.text.primary,
                        },
                        "&:hover": {
                          borderColor: theme.palette.text.primary,
                        },
                      }}
                      onChange={(event) => {
                        event.preventDefault();
                        setAvatarThemeSelect(event.target.value);
                      }}
                    >
                      {avatarThemeSelectOptions?.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Tooltip>
            </Grid>
            {/* Name */}
            <Grid item xs={12}>
              <Tooltip
                arrow
                title="Name of the AI Nerd you're hiring."
                placement="top-end"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: "baseline",
                    alignItems: { xs: "center", sm: "baseline" },
                    width: "100%",
                    gap: { xs: 2, sm: 2 },
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      //alignItems: "center",
                      //alignItems: "baseline",
                      alignItems: { xs: "baseline", sm: "center" },
                      width: "100%",
                      gap: { xs: "0px", sm: "0px" },
                      flexDirection: { xs: "column", sm: "column" },
                    }}
                  >
                    <Input
                      name="name"
                      placeholder="Name your Nerd"
                      sx={{ width: "auto", flexGrow: 1 }}
                      value={
                        generatingName ? "Please wait..." : formValues.name
                      }
                      disabled={generatingName}
                      onChange={(value) => {
                        setFormValuesHandler({ ...formValues, name: value });
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        width: "100%",
                        gap: "4px",
                        mt: 1,
                        pl: 2,
                      }}
                    >
                      <div>{`Characters: ${formValues?.name?.length}/60`}</div>
                      {!formPristine && (
                        <div style={{ color: "red" }}>
                          {formErrors.name?.errorMessage}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={handleGenerateAvatarName}
                    sx={{
                      minWidth: "150px",
                    }}
                    disabled={generatingName}
                  >
                    Generate Name
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
            {/* Generate Avatar */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  mt: 0,
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateAvatar}
                  loading={avatarGenerationLoading}
                  sx={{
                    width: "100%",
                    mb: 0,
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                  disabled={!formValid}
                >
                  Generate Avatar
                </LoadingButton>
                <Button
                  onClick={() => setUseUploader(!useUploader)}
                  disabled={avatarGenerationLoading}
                >
                  {useUploader ? "Use Generator" : "Or upload Your Own"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Grid>

      {/* Right Side */}
      <Grid item xs={12} md={6} sx={{ minHeight: "500px" }}>
        {useUploader ? (
          <>
            <UploadBase64Image dataOnComplete={onUserImageUpload} />
          </>
        ) : (
          <>
            <img
              src={avatarImageInUse || ""}
              alt="Avatar"
              ref={targetAvatarImageRef}
              style={{
                width: "100%",
                height: "auto",
                transition: "opacity 0.3s",
                borderRadius: "6%",
              }}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default NerdCreateView;
