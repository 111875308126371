import React, { useContext } from "react";
import CheckoutStep from "./CheckoutStep";
import NerdCreateView from "../NerdCreateView";
import { NerdCreateContext } from "../NerdCreateContext";

const StepsMain = (props) => {
  const { stepperStep, setStepperStep } = useContext(NerdCreateContext);

  return (
    <>
      {stepperStep === 0 && (
        <NerdCreateView nerdTemplate={props.nerdTemplate} />
      )}

      {stepperStep === 2 && <CheckoutStep />}
    </>
  );
};

export default StepsMain;
