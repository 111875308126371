import { useState, useEffect } from "react";
import useMainAuth from "app/hooks/useMainAuth";
import { Navigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

// const userHasPermission = (pathname, user, routes) => {
//   if (!user) {
//     return false;
//   }
//   const matched = routes.find((r) => r.path === pathname);

//   const authenticated =
//     matched && matched.auth && matched.auth.length ? matched.auth.includes(user.role) : true;
//   return authenticated;
// };

const AuthGuard = ({ children }) => {
  const { pathname } = useLocation();
  const { firebase } = useMainAuth();
  const { isAuthenticated } = firebase;
  // const { isAuthenticated, loginWithToken } = useMainAuth();
  // const [loading, setLoading] = useState(true);

  /* useEffect(() => {
    const token = localStorage.getItem('token') || null;
    if (token) {
      loginWithToken(token).then((success) => {
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [loginWithToken]);

  if (loading) {
    return <Box>Loading...</Box>;
  }
 */

  // const flatRoutes = flat(routes);

  //   const hasPermission = userHasPermission(pathname, user, routes);
  //   let authenticated = isAuthenticated && hasPermission;

  // // IF YOU NEED ROLE BASED AUTHENTICATION,
  // // UNCOMMENT ABOVE LINES
  // // AND COMMENT OUT BELOW authenticated VARIABLE

  if (isAuthenticated) return <>{children}</>;

  // check if page is login page using React Router

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
  /* return (
    <>
      <Box>
        <Box>AuthGuard | isAuthenticated: {isAuthenticated ? "true" : "false"}</Box>
      </Box>
    </>
  ); */
};

export default AuthGuard;
