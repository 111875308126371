import { useCallback } from "react";
import { toast } from "react-toastify";
import {
  startNewChat,
  sendChat, // Used for non-streaming messages.
  getThreadList,
  deleteChatById,
} from "app/services/ChatService";

import { get } from "lodash";
import memoize from "lodash.memoize";
import { useRefs } from "app/contexts/RefsContext";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";
import { WindowOutlined } from "@mui/icons-material";
import { ca } from "date-fns/locale";

export const useChatThreadsCallBacks = (globalState, dispatch) => {
  const { nerdDetailsByIdsCache } = useRefs();

  /**
   * Find new thread to start
   */
  const findNewThread = memoize(
    useCallback(() => {
      const allEmptySortedRecentThreads = globalState.chatThreads.threadsList
        .filter((td) => td.chatName === undefined)
        .sort((a, b) => a.createdAt - b.createdAt);

      if (allEmptySortedRecentThreads.length > 0) {
        return allEmptySortedRecentThreads[0];
      } else {
        return false;
      }
    }, [globalState.chatThreads])
  );

  /**
   * Updated chat with new oneOnOneWithNerdId
   */
  const updateChatThreadHandler = useCallback(() => {
    /* console.log(
      "updateChatThreadHandler | oneOnOneWithNerdId:",
      globalState.chatRoom.oneOnOneWithNerdId
    ); */
    if (!globalState.chatRoom.oneOnOneWithNerdId) {
      // TODO: Remove this when ready to show chat list
      return;
    }
    startNewChat({
      participants: [{ nerdId: globalState.chatRoom.oneOnOneWithNerdId }],
    })
      .then((data) => {
        // console.log("updateChatThreadHandler | data:", data);
        dispatch({
          type: ACTION_TYPES.SET_CURRENT_CHAT_ROOM,
          payload: data.data,
        });
        dispatch({
          type: ACTION_TYPES.SET_ONEONONE_WITH_NERDID,
          payload: data.data.participants[0],
        });
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE_LIST,
          payload: data?.data?.messages || [],
        });
        dispatch({
          type: ACTION_TYPES.SET_TRIGGER_SCROLL,
          payload: true,
        });
      })
      .then(() => {
        // Update threads list after starting new chat
        /* console.log(
          "updateChatThreadHandler | getThreadList | Updating threads list..."
        ); */
        getThreadList(globalState.chatRoom.oneOnOneWithNerdId).then((data) => {
          // console.log("updateChatThreadHandler | getThreadList | data:", data);
          if (data.status === 200) {
            dispatch({ type: "SET_THREADS_LIST", payload: data.data });
          }
        });
      })
      .catch((e) => {
        console.error("updateChatThreadHandler | Error: ", e);
        toast.error("Error starting new chat");
      });
  }, [dispatch, globalState?.chatRoom?.oneOnOneWithNerdId]);

  const startNewChatThreadHandler = useCallback(
    async (props) => {
      try {
        if (!globalState.chatInitialization.threadsListInitialized) {
          return;
        }

        if (!globalState.chatRoom.oneOnOneWithNerdId) {
          // TODO: Remove this when ready to show chat list
          toast.warn("Please select a chat to start");
          return;
        }

        const { participants, userRequested } = props || {};

        const newThreads = findNewThread();
        if (newThreads) {
          // console.log("startNewChatThreadHandler | newThreads:", newThreads);

          if (
            userRequested &&
            globalState.chatRoom.currentChatRoom.chatId === newThreads.chatId
          ) {
            toast.warn("Only one new thread allowed at a time");
          }
          // Only one new thread allowed at a time

          dispatch({
            type: ACTION_TYPES.SET_CURRENT_CHAT_ROOM,
            payload: newThreads,
          });
          dispatch({
            type: ACTION_TYPES.SET_ONEONONE_WITH_NERDID,
            payload: newThreads.participants[0],
          });
          dispatch({
            type: ACTION_TYPES.SET_MESSAGE_LIST,
            payload: newThreads.messages || [],
          });
          dispatch({
            type: ACTION_TYPES.SET_TRIGGER_SCROLL,
            payload: true,
          });
          dispatch({
            type: ACTION_TYPES.SET_TRIGGER_SCROLL_THREADS_LIST,
            payload: true,
          });
          dispatch({
            type: ACTION_TYPES.CREATE_NEW_THREAD_PENDING,
            payload: false,
          });

          return;
        }

        if (globalState.chatThreads.generateNewThreadPending) {
          // Prevent multiple calls while waiting for response
          if (userRequested) {
            toast.warn("Please wait while we start a new chat");
          }
          return;
        } else {
          dispatch({
            type: ACTION_TYPES.CREATE_NEW_THREAD_PENDING,
            payload: true,
          });
        }

        return await startNewChat({
          participants: [{ nerdId: globalState.chatRoom.oneOnOneWithNerdId }],
        })
          .then((data) => {
            dispatch({
              type: ACTION_TYPES.SET_CURRENT_CHAT_ROOM,
              payload: data.data,
            });
            dispatch({
              type: ACTION_TYPES.SET_ONEONONE_WITH_NERDID,
              payload: data.data.participants[0],
            });
            dispatch({
              type: ACTION_TYPES.SET_MESSAGE_LIST,
              payload: data?.data?.messages || [],
            });
            dispatch({
              type: ACTION_TYPES.SET_TRIGGER_SCROLL,
              payload: true,
            });
            dispatch({
              type: ACTION_TYPES.CREATE_NEW_THREAD_PENDING,
              payload: false,
            });
            dispatch({
              type: ACTION_TYPES.SET_TRIGGER_SCROLL_THREADS_LIST,
              payload: true,
            });

            if (userRequested) {
              getThreadList(globalState.chatRoom.oneOnOneWithNerdId)
                .then((data) => {
                  /* console.log(
                    "updateChatThreadHandler | getThreadList | data:",
                    data
                  ); */
                  if (data.status === 200) {
                    dispatch({ type: "SET_THREADS_LIST", payload: data.data });
                  }
                  dispatch({
                    type: ACTION_TYPES.CREATE_NEW_THREAD_PENDING,
                    payload: false,
                  });
                })
                .catch((e) => {
                  console.error("startNewChatThreadHandler | Error: ", e);
                  toast.error("Error starting new chat");
                  dispatch({
                    type: ACTION_TYPES.CREATE_NEW_THREAD_PENDING,
                    payload: false,
                  });
                  return false;
                });
            }

            return true;
          })
          .catch((e) => {
            console.error("startNewChatThreadHandler | Error: ", e);
            toast.error("Error starting new chat");
            dispatch({
              type: ACTION_TYPES.CREATE_NEW_THREAD_PENDING,
              payload: false,
            });
            return false;
          });
      } catch (e) {
        console.error("startNewChatThreadHandler | Error: ", e);
        toast.error("Error starting new chat");
        dispatch({
          type: ACTION_TYPES.CREATE_NEW_THREAD_PENDING,
          payload: false,
        });
        return false;
      }
    },
    [
      dispatch,
      findNewThread,
      globalState.chatInitialization.threadsListInitialized,
      globalState.chatRoom.oneOnOneWithNerdId,
      globalState.chatThreads.generateNewThreadPending,
    ]
  );

  /**
   * Get list of existing chats, if any
   */
  const getChatListUpdate = useCallback(() => {
    // TODO: Add options here to handle cases of multiple chat participants, loading of chat by thread, not by oneOnOne.
    if (!globalState.chatRoom.oneOnOneWithNerdId) return;

    /* console.log(
      "getChatListUpdate | oneOnOneWithNerdId:",
      globalState.chatRoom.oneOnOneWithNerdId
    ); */
    getThreadList(globalState.chatRoom.oneOnOneWithNerdId)
      .then((data) => {
        // console.log("getChatListUpdate | getThreadList | data:", data);
        if (data.status === 200) {
          dispatch({ type: "SET_THREADS_LIST", payload: data.data });
        }
        if (data.status === 204) {
          // startNewChatThreadHandler();
        }
      })
      .catch((e) => {
        console.error("getChatListUpdate | Error: ", e);
        // dispatch({ type: "SET_THREADS_LIST_LOADED", payload: true }); // to prevent infinite loop
        // toast.error("Error getting threads list");
      });
  }, [
    dispatch,
    globalState?.chatRoom?.oneOnOneWithNerdId,
    // startNewChatThreadHandler,
  ]);

  /**
   * Set searchThreadsList
   */
  const searchThreadsListHandler = useCallback(
    (value) => {
      // console.log("searchThreadsListHandler | value:", value);
      dispatch({ type: "SET_SEARCH_THREADS_LIST", payload: value });
    },
    [dispatch]
  );

  /**
   * Clear searchThreadsList
   */
  const clearSearchThreadsListHandler = useCallback(() => {
    dispatch({ type: "SET_CLEAR_SEARCH_THREADS_LIST" });
  }, [dispatch]);

  const memoizedFunction = memoize((nerdId, nerdsList) => {
    return nerdsList?.find((nerd) => nerd.nerdId === nerdId);
  });

  /**
   * Get Nerd details by ID
   */
  // Is it possible to memoize this to improve performance in case the same nerds are requested multiple times?

  const getNerdDetailsById = useCallback(
    (nerdId) => {
      // Check if we have a cached value for this nerdId
      if (nerdDetailsByIdsCache.current[nerdId]) {
        // console.log("Using cached result for nerdId:", nerdId);
        return nerdDetailsByIdsCache.current[nerdId];
      }

      // console.log("getNerdDetailsById | nerdId:", nerdId);
      const nerdDetailed = memoizedFunction(
        nerdId,
        globalState.chatParticipants.allNerdsList
      );
      // console.log("getNerdDetailsById | nerdDetailed:", nerdDetailed);

      // Store the result in cache for future use
      nerdDetailsByIdsCache.current[nerdId] = nerdDetailed;

      return nerdDetailed;
    },
    [
      globalState.chatParticipants.allNerdsList,
      memoizedFunction,
      nerdDetailsByIdsCache,
    ]
  );

  const deleteChatByIdHandler = useCallback(
    (inboundChatId) => {
      deleteChatById({
        chatId: inboundChatId,
      })
        .then((data) => {
          if (data.status < 300) {
            // Get fresh chats
            getChatListUpdate();
            // Select most recent chat
            const mostRecentChat = globalState.chatThreads.threadsList[0];
            if (mostRecentChat) {
              dispatch({
                type: ACTION_TYPES.UPDATE_CURRENT_CHAT_ROOM,
                payload: { action: true, chatId: mostRecentChat.chatId },
              });
            }
          }
        })
        .catch((e) => {
          console.error("deleteChatByIdHandler | Error: ", e);
          toast.error("Error deleting chat");
        });
    },
    [globalState.chatThreads.threadsList, getChatListUpdate, dispatch]
  );

  const resetChatThreads = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET_CHAT_THREADS });
  }, [dispatch]);

  return {
    getChatListUpdate,
    searchThreadsListHandler,
    clearSearchThreadsListHandler,
    getNerdDetailsById,
    deleteChatByIdHandler,
    updateChatThreadHandler,
    startNewChatThreadHandler,
    resetChatThreads,
    findNewThread,
  };
};
