import axios from "app/utils/axiosConfig";
import { toast } from "react-toastify";

export const getAllNerds = async () => {
  return await axios.get("/v1/nerds/").catch((e) => {
    console.log("getAllNerds | e:", e);
    return { data: [] };
  });
};

export const getNerdById = async ({ nerdId }) => {
  return await axios({
    method: "get",
    url: "/v1/nerds/" + nerdId,
  }).catch((e) => {
    console.log("getNerdById | e:", e);
    return { data: [] };
  });
};

export const getListOfNerdsByIds = async ({ nerdIds }) => {
  /*
   * Validate data
   */
  if (!nerdIds) {
    throw new Error("nerdIds is required");
  }

  // Send request
  return axios({
    method: "post",
    url: "/v1/nerds/list",
    data: {
      nerdIds,
    },
  }).catch((e) => {
    console.log("getListOfNerdsByIds | e:", e);
    return { data: [] };
  });
};

export const startNewChat = async ({ participants }) => {
  return await axios({
    method: "post",
    url: "/v1/chat/",
    data: {
      chat: {
        participants,
      },
    },
  }).catch((e) => {
    console.log("startNewChat | e:", e);
    return { data: [] };
  });
};

export const sendStreamChat = async ({
  chatId,
  message,
  socketIOClientId,
  history,
}) => {
  try {
    if (!chatId) {
      throw new Error("chatId is required");
    }
    if (!message) {
      throw new Error("message is required");
    }
    if (!socketIOClientId) {
      throw new Error("socketIOClientId is required");
    }

    console.log("sendStreamChat | history:", history);

    const config = {
      method: "post",
      url: "https://flowise.admagnetize.com/api/v1/prediction/056bf8b3-6555-4453-9ab3-c90b1b7b26e1", // chatId
      data: {
        question: message,
        socketIOClientId: socketIOClientId,
        history:
          history.map((item) => {
            return {
              message: item?.message,
              type:
                item?.MessageFromType === "User" ? "userMessage" : "apiMessage",
            };
          }) || [],
      },
      headers: {
        Authorization: "Bearer jYFyWT1+ALWVgbCKUqS0lQU7xKATQr0DhSDm3p6XA1c=",
      },
    };

    console.log("sendStreamChat | config:", config);

    return await axios(config).catch((e) => {
      console.log("sendStreamChat | e:", e);
      return { data: [] };
    });
  } catch (error) {
    console.error("Error sending stream chat:", error);
    return { data: [] };
  }
};

export const sendChat = async ({ chatId, message, history }) => {
  return new Promise(async (resolve, reject) => {
    if (!chatId) {
      throw new Error("chatId is required");
    }
    if (!message) {
      throw new Error("message is required");
    }

    const config = {
      method: "post",
      url: "https://flowise.admagnetize.com/api/v1/prediction/056bf8b3-6555-4453-9ab3-c90b1b7b26e1", // chatId
      data: {
        question: message,
        history:
          history.map((item) => {
            return {
              message: item?.text,
              type: item?.type,
            };
          }) || [],
      },
      headers: {
        Authorization: "Bearer jYFyWT1+ALWVgbCKUqS0lQU7xKATQr0DhSDm3p6XA1c=",
      },
    };
    return await axios(config).catch((e) => {
      return { data: [] };
    });
  });
};

export const getThreadList = async (oneOnOneWithNerdId) => {
  // console.log("getThreadList | oneOnOneWithNerdId:", oneOnOneWithNerdId);
  if (oneOnOneWithNerdId) {
    return await axios
      .get("/v1/chat/nerdId/" + oneOnOneWithNerdId)
      .catch((e) => {
        console.log("getThreadList | e:", e);
        return { data: [] };
      });
  }

  return await axios.get("/v1/chat/").catch((e) => {
    console.log("getThreadList | e:", e);
    return { data: [] };
  });
};

export const generateChatName = async (message) => {
  return await axios
    .post(
      "https://flowise.admagnetize.com/api/v1/prediction/033848c5-4a84-4577-9401-e91692558fc0",
      { question: message }
    )
    .catch((e) => {
      console.log("generateChatName | e:", e);
      return { data: [] };
    });
};

export const setChatName = async ({ chatId, chatName, meta_description }) => {
  return await axios({
    method: "put",
    url: "/v1/chat/" + chatId,
    data: {
      chat: {
        chatName,
        meta_description,
      },
    },
  }).catch((e) => {
    console.log("setChatName | e:", e);
    return { data: [] };
  });
};

export const getChatById = async ({ chatId }) => {
  return await axios.get("/v1/chat/" + chatId).catch((e) => {
    console.log("getChatById | e:", e);
    return { data: [] };
  });
};

export const getChatMessagesByChatId = async ({ chatId }) => {
  return await axios.get("/v1/chat/" + chatId + "/messages").catch((e) => {
    console.log("getChatMessagesByChatId | e:", e);
    return { data: [] };
  });
};

export const pushNewMessageToChatHistoryByChatId = async ({
  chatId,
  chatMessage,
}) => {
  return await axios({
    method: "post",
    url: "/v1/chat/" + chatId + "/messages",
    data: {
      chatMessage,
    },
  }).catch((e) => {
    console.log("pushNewMessageToChatHistoryByChatId | e:", e);
    return { data: [] };
  });
};

export const deleteChatById = async ({ chatId }) => {
  return await axios.delete("/v1/chat/" + chatId).catch((e) => {
    console.log("deleteChatById | e:", e);
    return { data: [] };
  });
};

// MOCK UP ENDPOINTS BELOW

export const getContactById = (id) => {
  return axios.get("/api/chat/contacts", { data: id });
};

export const getRecentContact = (id) => {
  return axios.get("/api/chat/contacts/recent", { data: id });
};

export const getAllContact = () => {
  return axios.get("/api/chat/contacts/all");
};

export const getChatRoomByContactId = (currentUser, contactId) => {
  return axios.get("/api/chat/chat-room", {
    data: { currentUser, contactId },
  });
};

export const deleteMessage = (message) => {
  return axios.post("/api/chat/delete", message);
};

export const sendNewMessage = (message) => {
  return axios.post("/api/chat/add", message);
};

export const updateMessage = (message) => {
  return axios.post("/api/chat/update", message);
};
