import Loadable from "app/components/Loadable";
import { MainChatProvider } from "app/contexts/chat/MainChatContext";
import React, { lazy } from "react";

const AppChat = Loadable(lazy(() => import("./AppChat")));
const OneChatMain = Loadable(lazy(() => import("./OneChat/Main"))); // One on One Chat
const TeamChat = Loadable(lazy(() => import("./TeamChat"))); // Group Chat ( Team Chat )

const chatRoutes = [
  {
    path: "/chat",
    element: (
      <MainChatProvider>
        <AppChat />
      </MainChatProvider>
    ),
  }, // Group Chat ( Team Chat )
  {
    path: "/chat/:chatId",
    element: (
      <MainChatProvider>
        <OneChatMain />
      </MainChatProvider>
    ),
  }, // Open specifc Thread
  {
    path: "/chat/nerd/:nerdId",
    element: (
      <MainChatProvider>
        <OneChatMain />
      </MainChatProvider>
    ),
  }, // Start a new One on One Chat or find the latest chat with this nerd

  {
    path: "/team-chat",
    element: (
      <MainChatProvider>
        <TeamChat />
      </MainChatProvider>
    ),
  },
];

export default chatRoutes;
