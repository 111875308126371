export const CHAT_SOCKET_ACTIONS = {
  // CHAT SOCKET ACTIONS
  SET_SOCKET_ID: "SET_SOCKET_ID",
  RESET_CHAT_SOCKET: "RESET_CHAT_SOCKET",
  PUSH_TOKEN_TO_STREAM_ARRAY: "PUSH_TOKEN_TO_STREAM_ARRAY",
  POP_TOKEN_FROM_STREAM_ARRAY: "POP_TOKEN_FROM_STREAM_ARRAY",
  CLEAR_FIFO_TOKEN: "CLEAR_FIFO_TOKEN",
  SET_TOKEN_PROCESS_HEARTBEAT: "SET_TOKEN_PROCESS_HEARTBEAT",
  CLEAR_STREAM_ARRAY: "CLEAR_STREAM_ARRAY",
};
