import { set } from "lodash";
import React, { useState, useEffect } from "react";
import { Uploader, Notification } from "rsuite";

const UploadBase64Image = ({ dataOnComplete }) => {
  const [fileData, setFileData] = useState(null);

  const handleOnUpload = (file) => {
    // Ensure there's a file to read
    if (file && file.blobFile) {
      const blobFile = file.blobFile;

      // Ensure the file is of type JPG or PNG
      if (blobFile.type === "image/jpeg" || blobFile.type === "image/png") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // The result contains the base64 representation of the image
          setFileData(event.target.result);
        };

        reader.readAsDataURL(blobFile);
      } else {
        Notification.error({
          title: "Error",
          description: "Only JPG or PNG files are allowed",
        });
      }
      return false; // Return false to prevent actual upload
    }
  };

  useEffect(() => {
    console.log("fileData: ", fileData);
    if (fileData) {
      dataOnComplete(fileData);
      setFileData(null);
    }
  }, [dataOnComplete, fileData]);

  return (
    <Uploader
      // onChange={handleUploadChange}
      // onAfterAddFile={handleAfterAddFile}
      onUpload={handleOnUpload}
      draggable
      autoUpload={true} // Ensure files aren't automatically uploaded
      action="#"
    >
      <div style={{ lineHeight: "200px", textAlign: "center" }}>
        Drag & Drop to Upload
      </div>
    </Uploader>
  );
};

export default UploadBase64Image;
