import { useEffect } from "react";
import { Grid } from "@mui/material";
import ResetPasswordForm from "./Forms/ResetPasswordForm";
import SetPasswordForm from "./Forms/SetPasswordForm";
import useMainAuth from "app/hooks/useMainAuth";
import Loading from "app/components/MatxLoading";

const Password = () => {
  const { firebase } = useMainAuth();
  const { user } = firebase;

  useEffect(() => {
    // console.log("user:", user);
    if (user) {
      const isGoogleAuth = user.providerData.some(
        (provider) => provider.providerId === "google.com"
      );
      console.log("isGoogleAuth:", isGoogleAuth);
      const hasEmailPasswordLink = user.providerData.some(
        (provider) => provider.providerId === "password"
      );
      console.log("hasEmailPasswordLink:", hasEmailPasswordLink);
    }
  }, [user]);

  const userHasEmailPasswordLink = () => {
    if (!user) return false;
    return user.providerData.some(
      (provider) => provider.providerId === "password"
    );
  };

  const userIsGoogleAuth = () => {
    if (!user) return false;
    return user.providerData.some(
      (provider) => provider.providerId === "google.com"
    );
  };

  const showSetPasswordForm = () => {
    if (!user) return false;
    // If user is Google Auth and hasn't yet linked an email/password to their account, show the set password form.
    if (userIsGoogleAuth() && !userHasEmailPasswordLink()) {
      return true;
    }

    return false;
  };

  return (
    <>
      {!user && <Loading />}
      <Grid container spacing={5}>
        {/**
         * SET PASSWORD FORM
         * Only available to users who have signed up using Google Auth and haven't yet linked an email/password to their account.
         * For users who signed up with other methods or have already set an email/password, this option should not be shown.        *
         */}
        {showSetPasswordForm() && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <SetPasswordForm />
          </Grid>
        )}
        {/**
         * CHANGE PASSWORD FORM
         * Only available to users who have signed up using email/password.
         * Users who signed up with Google Auth, but have since SET their passwords should also be able to change their password.
         * For users who signed up with other methods, this option should not be shown.
         * Users who have signed up using email/password but haven't yet verified their email should be able to change their password.
         */}
        {userHasEmailPasswordLink() && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ResetPasswordForm />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Password;
