import {
  Box,
  Button,
  Card,
  Drawer,
  Grid,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FlexBox } from "app/components/FlexBox";
import Apps from "app/components/icons/Apps";
import DeleteOutlined from "app/components/icons/DeleteOutlined";
import DevicesApple from "app/components/icons/DevicesApple";
import DollarOutlined from "app/components/icons/DollarOutlined";
import FileOutlined from "app/components/icons/FileOutlined";
import Fingerprint from "app/components/icons/Fingerprint";
import Instagram from "app/components/icons/Instagram";
import Key from "app/components/icons/Key";
import Link from "app/components/icons/Link";
import LockOutlined from "app/components/icons/LockOutlined";
import NotificationOutlined from "app/components/icons/NotificationOutlined";
import PremiumOutlined from "app/components/icons/PremiumOutlined";
import SettingsOutlined from "app/components/icons/SettingsOutlined";
import UserOutlined from "app/components/icons/UserOutlined";
import { H5 } from "app/components/Typography";
import { Fragment, useState } from "react";
import ApiKeys from "./ApiKeys";
import BasicInformation from "./BasicInformation";
import Billing from "./Billing";
import ConnectedAccounts from "./ConnectedAccounts";
import DeleteAccount from "./DeleteAccount";
import Notifications from "./Notifications";
import Password from "./Password/Password";
import Preferences from "./Preferences";
import RecentDevices from "./RecentDevices";
import Referrals from "./Referrals";
import SocialAccounts from "./SocialAccounts";
import Statements from "./Statements";
import TwoStepVerification from "./TwoStepVerification";

// styled component
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  overflow: "hidden",
  position: "relative",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "0.6rem 1.5rem",
  justifyContent: "flex-start",
  color: theme.palette.text.primary,
}));

const Account = () => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [active, setActive] = useState("Basic Information");
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const style = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
    "&::before": {
      left: 0,
      width: 4,
      content: '""',
      height: "100%",
      position: "absolute",
      transition: "all 0.3s",
      backgroundColor: theme.palette.primary.main,
    },
  };

  function TabListContent() {
    return (
      <FlexBox flexDirection="column">
        {tabList.map(({ id, name, Icon, disabled }) => (
          <StyledButton
            key={id}
            startIcon={<Icon sx={{ color: "text.disabled" }} />}
            sx={active === name ? style : { "&:hover": style }}
            onClick={() => {
              setActive(name);
              setOpenDrawer(false);
            }}
            disabled={disabled}
          >
            {name}
          </StyledButton>
        ))}
      </FlexBox>
    );
  }

  return (
    <Box p={4}>
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          {downMd ? (
            <Fragment>
              <FlexBox alignItems="center" gap={1}>
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setOpenDrawer(true)}
                >
                  <Apps sx={{ color: "primary.main" }} />
                </IconButton>

                <H5>Show More</H5>
              </FlexBox>

              <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Box padding={1}>
                  <TabListContent />
                </Box>
              </Drawer>
            </Fragment>
          ) : (
            <Card sx={{ padding: "1rem 0" }}>
              <TabListContent />
            </Card>
          )}
        </Grid>

        <Grid item md={9} xs={12}>
          {active === tabList[0].name && (
            <BasicInformation disabled={tabList[0].disabled} />
          )}
          {active === tabList[1].name && (
            <Password disabled={tabList[1].disabled} />
          )}
          {active === tabList[2].name && (
            <Preferences disabled={tabList[2].disabled} />
          )}
          {active === tabList[3].name && (
            <RecentDevices disabled={tabList[3].disabled} />
          )}
          {active === tabList[4].name && (
            <Notifications disabled={tabList[4].disabled} />
          )}
          {active === tabList[5].name && (
            <TwoStepVerification disabled={tabList[5].disabled} />
          )}
          {active === tabList[6].name && (
            <ConnectedAccounts disabled={tabList[6].disabled} />
          )}
          {active === tabList[7].name && (
            <SocialAccounts disabled={tabList[7].disabled} />
          )}
          {active === tabList[8].name && (
            <Billing disabled={tabList[8].disabled} />
          )}
          {active === tabList[9].name && (
            <Statements disabled={tabList[9].disabled} />
          )}
          {active === tabList[10].name && (
            <Referrals disabled={tabList[10].disabled} />
          )}
          {active === tabList[11].name && (
            <ApiKeys disabled={tabList[11].disabled} />
          )}
          {active === tabList[12].name && (
            <DeleteAccount disabled={tabList[12].disabled} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const tabList = [
  { id: 1, name: "Basic Information", Icon: UserOutlined },
  { id: 2, name: "Password", Icon: LockOutlined },
  { id: 3, name: "Preferences", Icon: SettingsOutlined, disabled: true },
  { id: 4, name: "Recent Devices", Icon: DevicesApple, disabled: true },
  { id: 5, name: "Notifications", Icon: NotificationOutlined, disabled: true },
  { id: 6, name: "Two-step verification", Icon: Fingerprint, disabled: true },
  { id: 7, name: "Connected accounts", Icon: Link, disabled: true },
  { id: 8, name: "Social Account", Icon: Instagram, disabled: true },
  { id: 9, name: "Billing", Icon: DollarOutlined, disabled: true },
  { id: 10, name: "Statements", Icon: FileOutlined, disabled: true },
  { id: 11, name: "Referrals", Icon: PremiumOutlined, disabled: true },
  { id: 12, name: "API Keys", Icon: Key, disabled: true },
  { id: 13, name: "Delete account", Icon: DeleteOutlined, disabled: true },
];

export default Account;
