import { useCallback } from "react";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";
import { useRefs } from "app/contexts/RefsContext";

export const useChatSocketCallBacks = (globalState, dispatch) => {
  // const { globalStateRef } = useRefs();

  /* const pushTokenToMessageList = useCallback(
    (token) => {
      // return new Promise((resolve, reject) => {

      console.log("Utils.js | pushTokenToMessageList | token:", token);

      const prevMessages = globalState.chatMessages.messageList;
      console.log(
        "Utils.js | pushTokenToMessageList | prevMessages:",
        prevMessages
      );

      let lastMessage = null;

      if (prevMessages && prevMessages.length > 0) {
        const lastMessageMaybe = prevMessages[prevMessages.length - 1];
        if (lastMessageMaybe.type === "token") {
          lastMessage = lastMessageMaybe;
        }
      }

      console.log(
        "Utils.js | pushTokenToMessageList | lastMessage:",
        lastMessage
      );

      if (
        prevMessages &&
        prevMessages.length > 0 &&
        lastMessage &&
        lastMessage.type === "token"
      ) {
        // Update exsting token stream, adding a new token
        const newText = `${lastMessage.message}${token}`;
        console.log("Utils.js | pushTokenToMessageList | newText:", newText);

        const newMessage = { ...lastMessage, message: newText };
        console.log(
          "Utils.js | pushTokenToMessageList | newMessage:",
          newMessage
        );

        const messageToReturnLastAsUpdatedToken = prevMessages.slice(0, -1);
        messageToReturnLastAsUpdatedToken.push(newMessage);
        console.log(
          "Utils.js | pushTokenToMessageList | ADDING TOKEN:",
          messageToReturnLastAsUpdatedToken
        );
        // resolve(messageToReturnLastAsUpdatedToken);
        return messageToReturnLastAsUpdatedToken;
      }

      const newMessage = { message: token, type: "token" };
      console.log(
        "Utils.js | pushTokenToMessageList | newMessage:",
        newMessage
      );

      const messageToReturnLastAsInitialToken = prevMessages;

      messageToReturnLastAsInitialToken.push(newMessage);
      console.log(
        "Utils.js | pushTokenToMessageList | INITIAL TOKEN:",
        messageToReturnLastAsInitialToken
      );
      // resolve(messageToReturnLastAsInitialToken);
      return messageToReturnLastAsInitialToken;
      // });
    },
    [globalState.chatMessages.messageList]
  ); */

  const resetChatSocket = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET_CHAT_SOCKET });
  }, [dispatch]);

  return {
    resetChatSocket,
    // pushTokenToMessageList,
  };
};
