export const CHAT_INIT_ACTIONS = {
  CHAT_INIT_SET_USER_INITIALIZED: "CHAT_INIT_SET_USER_INITIALIZED",
  CHAT_INIT_SET_GET_ALL_NERDS_INITIALIZED:
    "CHAT_INIT_SET_GET_ALL_NERDS_INITIALIZED",
  CHAT_INIT_SET_CURRENT_CHAT_ROOM_INITIALIZED:
    "CHAT_INIT_SET_CURRENT_CHAT_ROOM_INITIALIZED",
  CHAT_INIT_NEW_SERVER_CHAT_INITIALIZED:
    "CHAT_INIT_NEW_SERVER_CHAT_INITIALIZED",
  CHAT_INIT_MESSAGE_LIST_INITIALIZED: "CHAT_INIT_MESSAGE_LIST_INITIALIZED",
  CHAT_INIT_THREADS_LIST_INITIALIZED: "CHAT_INIT_THREADS_LIST_INITIALIZED",
  CHAT_INIT_SET_PARTICIPANTS_LIST_READY:
    "CHAT_INIT_SET_PARTICIPANTS_LIST_READY",
  CHAT_INIT_SET_CHAT_ROOM_INITIALIZED: "CHAT_INIT_SET_CHAT_ROOM_INITIALIZED",

  SET_CHAT_INITIALIZED: "SET_CHAT_INITIALIZED",
  CHAT_INIT_START_NEW_CHAT: "CHAT_INIT_START_NEW_CHAT",
  RESET_CHAT_INIT: "RESET_CHAT_INIT",
};
