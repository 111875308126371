import { createContext, useRef, useContext, useEffect } from "react";

const RefsContext = createContext();

export const RefsProvider = ({ children }) => {
  const socketRef = useRef(null);
  const resetTimeoutRef = useRef(null);
  const debouncerForScrollDownRef = useRef(null);
  const currentlyProcessingMessageId = useRef(null);
  const tokenTimeoutRef = useRef(null);
  const globalStateRef = useRef(null);
  const nerdDetailsByIdsCache = useRef({}); // This will hold the cached results

  return (
    <RefsContext.Provider
      value={{
        socketRef,
        resetTimeoutRef,
        currentlyProcessingMessageId,
        debouncerForScrollDownRef,
        tokenTimeoutRef,
        globalStateRef,
        nerdDetailsByIdsCache,
      }}
    >
      {children}
    </RefsContext.Provider>
  );
};

export const useRefs = () => {
  const context = useContext(RefsContext);
  if (!context) {
    throw new Error("useRefs must be used within a RefsProvider");
  }
  return context;
};
