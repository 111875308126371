import { useCallback } from "react";
/* CHAT MESSAGES */

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";

export const initialChatMessagesState = {
  messageList: [],
  intialMessageListFetchCompleted: false,
  streamingMessage: false,
  syncLastMsgToServerTrigger: false,
  messageSyncQueue: [],
};

export const chatMessagesReducer = (state, action) => {
  let actionMatched = true; // Default to true

  const newState = (() => {
    /* if (action.type === ACTION_TYPES.RESET_ALL) {
      // Maybe do some special chat-specific reset logic here
      return initialChatMessagesState;
    } */

    switch (action.type) {
      case ACTION_TYPES.SET_MESSAGE_LIST:
        return {
          ...state,
          messageList: action.payload,
          triggerScrollToBottom: true,
        };
      case ACTION_TYPES.ADD_MESSAGE:
        return {
          ...state,
          messageList: [...state.messageList, action.payload],
          triggerScrollToBottom: true,
        };
      case ACTION_TYPES.ADD_MESSAGE_TO_SYNC_QUEUE:
        return {
          ...state,
          messageSyncQueue: [...state.messageSyncQueue, action.payload],
        };
      case ACTION_TYPES.REMOVE_MESSAGE_FROM_SYNC_QUEUE_BY_ID:
        return {
          ...state,
          messageSyncQueue: state.messageSyncQueue.filter(
            (message) => message.frontEndUniqueMessageId !== action.payload
          ),
        };
      case ACTION_TYPES.SET_STREAMING_MESSAGE:
        return {
          ...state,
          streamingMessage: action.payload,
        };
      case ACTION_TYPES.SET_SYNC_STREAM_MESSAGE:
        return { ...state, syncLastMsgToServerTrigger: action.payload };
      case ACTION_TYPES.SET_INITIAL_MESSAGE_LIST_FETCH_COMPLETED:
        return { ...state, intialMessageListFetchCompleted: action.payload };

      case ACTION_TYPES.RESET_CHAT_MESSAGES:
        return initialChatMessagesState;
      default:
        actionMatched = false;
        return { ...state };
    }
  })();

  return [newState, actionMatched];
};

export const chatMessagesReducerFetch = (state, action) => {
  const [newState, actionWasHandled] = chatMessagesReducer(state, action);
  if (actionWasHandled) {
    // console.log("chatMessagesReducerFetch | action:", action);
    /* console.log("--------------------------------------------");
    console.log("chatMessagesReducerFetch | action: ", action);
    console.log(
      "chatMessagesReducerFetch | payload:",
      JSON.stringify(action.payload, null, 2)
    );
    console.log("chatMessagesReducerFetch | (BEFORE) state:", state);
    console.log("chatMessagesReducerFetch | (AFTER) newState:", newState);
    console.log("--------------------------------------------"); */
  }
  return { newStateForKey: newState, actionWasHandled };
};
