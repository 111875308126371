import { useCallback } from "react";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";
import { useRefs } from "app/contexts/RefsContext";
import { getAllNerds } from "app/services/ChatService";

export const useChatInitCallBacks = (globalState, dispatch) => {
  // const { globalStateRef } = useRefs();

  const resetChatSocket = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET_CHAT_SOCKET });
  }, [dispatch]);

  /**
   * Get list of All Nerds
   * Required for meta information of past participants who may have left the chat.
   * Their avatar information for past messages is still req. to display old messages.
   */
  const getAllNerdsUpdate = useCallback(async () => {
    return await getAllNerds()
      .then(({ data }) => {
        // console.log("getAllNerds | data: ", data);
        if (data && data.length > 0) {
          dispatch({
            type: "SET_ALL_NERDS_LIST",
            payload: data,
          });
          return true;
        }
        if (data && data.length === 0) {
          dispatch({
            type: "SET_ALL_NERDS_LIST",
            payload: [],
          });
          return true;
        }
      })
      .catch((error) => {
        console.error("getAllNerds | error: ", error);
        return false;
      });
  }, [dispatch]);

  return {
    resetChatSocket,
    getAllNerdsUpdate,
  };
};
