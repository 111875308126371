import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AuthProvider } from "app/contexts/FirebaseAuthContext";
import { ServerProvider } from "app/contexts/ServerContext";
import { MainAuthProvider } from "app/contexts/MainAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";
import { StableDiffusionProvider } from "app/contexts/stableDiffusion/StableDiffusionContext";
import { NerdsProvider } from "app/contexts/nerds/MainNerdsContext";
import { RefsProvider } from "app/contexts/RefsContext";

import Modals from "app/views/modals/Modals";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import store from "./redux/store";
import routes from "./routes";
import "../fake-db";
import AxiosProvider from "app/utils/AxiosProivder";
import "rsuite/dist/rsuite.min.css";
import "@fontsource/inter"; // Needed for @mui/joy
import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  const content = useRoutes(routes);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <SettingsProvider>
            <AxiosProvider>
              <MatxTheme>
                <MainAuthProvider>
                  <RefsProvider>
                    <StableDiffusionProvider>
                      <NerdsProvider>
                        <>
                          {content}
                          <Modals />
                        </>
                      </NerdsProvider>
                    </StableDiffusionProvider>
                  </RefsProvider>
                </MainAuthProvider>
              </MatxTheme>
            </AxiosProvider>
          </SettingsProvider>
        </Provider>
      </LocalizationProvider>
      <ToastContainer />
    </>
  );
};

export default App;
