import { React, useEffect } from "react";
import { createContext, useState, useCallback } from "react";
import { MatxLayoutSettings } from "app/components/MatxLayout/settings";
import { merge } from "lodash";
import { Alert, Button, Snackbar, styled } from "@mui/material";
import { amber, green } from "@mui/material/colors";

const SettingsContext = createContext({
  updateSettings: () => {},
  settings: MatxLayoutSettings,
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || MatxLayoutSettings
  );

  const handleUpdateSettings = useCallback(
    (update = {}) => {
      const marged = merge({}, currentSettings, update);
      setCurrentSettings(marged);
    },
    [currentSettings]
  );

  const updateGlobalAlert = useCallback(
    (show, message, severity) => {
      handleUpdateSettings({
        showGlobalAlert: {
          show: show || false,
          message: message || "",
          severity: severity || "success",
        },
      });
    },
    [handleUpdateSettings]
  );

  useEffect(() => {
    <Snackbar
      open={currentSettings.showGlobalAlert.show}
      autoHideDuration={6000}
      onClose={updateGlobalAlert}
    >
      <Alert
        onClose={updateGlobalAlert}
        severity={currentSettings.showGlobalAlert.severity}
        sx={{ width: "100%" }}
        variant="filled"
      >
        {currentSettings.showGlobalAlert.message}
      </Alert>
    </Snackbar>;

    return () => {
      if (currentSettings.showGlobalAlert.show) {
        updateGlobalAlert(false);
      }
    };
  }, [
    currentSettings.showGlobalAlert.message,
    currentSettings.showGlobalAlert.severity,
    currentSettings.showGlobalAlert.show,
    updateGlobalAlert,
  ]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        updateSettings: handleUpdateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
