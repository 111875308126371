// AxiosProvider.jsx
import { useEffect } from "react";
// import useServer from "app/hooks/useServer.js";
import useSettings from "app/hooks/useSettings.js";

import { setupAxios } from "app/utils/axiosConfig";
import { nodejsConfig, setup } from "../../config.js";

const AxiosProvider = ({ children }) => {
  /* const { signInWithServer, updateUserTokenOnServer, logoutServer } =
    useServer(); */

  const { settings, updateSettings } = useSettings();
  useEffect(() => {
    if (!settings.isAxiosSetup) {
      updateSettings({ isAxiosSetup: true });
      setupAxios(
        setup,
        nodejsConfig,
        settings,
        updateSettings
      );
    }
  }, [settings, updateSettings]);

  // setupAxios(setup, nodejsConfig, signInWithServer, updateUserTokenOnServer);

  return children;
};

export default AxiosProvider;
