import { createContext, useReducer, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { getModels } from "app/services/StableDefussionServices";
import useMainAuth from "app/hooks/useMainAuth";

const StableDiffusionContext = createContext();

const initialState = {
  models: [],
  modelsLoaded: false,
};

const reducer = (state, action) => {
  console.log("action:", action);
  console.log("state:", state);
  switch (action.type) {
    case "SET_MODELS":
      return {
        ...state,
        models: action.payload,
        modelsLoaded: true,
      };
    case "RESET":
      return initialState;
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const StableDiffusionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { firebase, nodeServer } = useMainAuth();

  const getModelsAction = async () => {
    getModels()
      .then((response) => {
        console.log("response:", response);

        if (response.status === 200) {
          dispatch({ type: "SET_MODELS", payload: response.data });
        }

        if (response.status === 204) {
          toast.info("No models found.");
        }

        if (response.status === 500) {
          toast.error("Error getting models.");
        }
      })
      .catch((error) => {
        console.error("Error getting models:", error);
        toast.error("Error getting models.");
      });
  };

  useEffect(() => {
    if (firebase.isAuthenticated && nodeServer.isAuthenticated) {
      if (!state.modelsLoaded) {
        getModelsAction();
      }
    }
  }, [
    firebase.isAuthenticated,
    nodeServer.isAuthenticated,
    state.modelsLoaded,
  ]);

  return (
    <StableDiffusionContext.Provider
      value={{
        ...state,
        getModelsAction,
        reset: () => {
          setTimeout(() => {
            dispatch({ type: "RESET" });
          }, 200);
        },
      }}
    >
      {children}
    </StableDiffusionContext.Provider>
  );
};

export { StableDiffusionProvider, StableDiffusionContext };
