import { useCallback } from "react";

import memoize from "lodash.memoize";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";


export const useChatParticipantsCallBacks = (globalState, dispatch) => {
  /**
   * Get Nerd details by ID
   */
  // Is it possible to memoize this to improve performance in case the same nerds are requested multiple times?

  const memoizedFunction = memoize((nerdId, nerdsList) => {
    return nerdsList.find((nerd) => nerd.nerdId === nerdId);
  });

  const getNerdDetailsById = useCallback(
    (nerdId) => {
      const nerdDetailed = memoizedFunction(
        nerdId,
        globalState.chatParticipants.allNerdsList
      );
      return nerdDetailed;
    },
    [globalState.chatParticipants.allNerdsList, memoizedFunction]
  );

  /**
   * Reset context
   */
  const resetChatParticipants = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET_CHAT_PARTICIPANTS });
  }, [dispatch]);

  return { getNerdDetailsById, resetChatParticipants };
};
