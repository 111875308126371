import { io } from "socket.io-client";

// Declare socket at the module level so it can be accessed by multiple functions
let socket;

export function initializeSocket(eventCallbacks) {
  socket = io("https://flowise.admagnetize.com/", {
    extraHeaders: {
      Authorization: "Bearer jYFyWT1+ALWVgbCKUqS0lQU7xKATQr0DhSDm3p6XA1c=",
    },
  });

  Object.entries(eventCallbacks).forEach(([event, handler]) =>
    socket.on(event, handler)
  );

  return socket;
}

export function disconnectSocket() {
  if (socket) {
    socket.disconnect();
  }
}
