import React, { useMemo } from "react";
import Nationalities from "app/constants/nationalities.json";

import { differenceInSeconds, format, parseISO } from "date-fns";

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");

    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substring(2, tempId.length - 1);
  return uid;
}

export function formatDate(dateString, formatType) {
  // console.log("formatDate | dateString:", dateString);
  const date = parseISO(dateString);
  return format(date, formatType);

  // Usage Example
  // let formattedDate = formatDate("2014-09-16", 'd MMMM yyyy');
  // console.log(formattedDate);  // Outputs: "16 September 2014"
}

export function useSortedNationalitiesListList() {
  const sortedNationalities = useMemo(() => {
    return [...Nationalities].sort((a, b) => {
      // First, compare by nationality
      const nationalityComparison = a.nationality.localeCompare(b.nationality);
      if (nationalityComparison !== 0) {
        return nationalityComparison;
      }

      // If nationality is the same, compare by en_short_name
      return a.en_short_name.localeCompare(b.en_short_name);
    });
  }, []);
  return sortedNationalities;
}
