export const CHAT_THREADS_ACTIONS = {
  // CHAT THREADS ACTIONS
  SET_THREADS_LIST: "SET_THREADS_LIST",
  SET_THREADS_LIST_LOADED: "SET_THREADS_LIST_LOADED",
  SET_FILTERED_THREADS_LIST: "SET_FILTERED_THREADS_LIST",
  GENERATE_CHAT_NAME: "GENERATE_CHAT_NAME",
  GENERATE_CHAT_NAME_UPDATE_STATE: "GENERATE_CHAT_NAME_UPDATE_STATE",
  SET_SEARCH_THREADS_LIST: "SET_SEARCH_THREADS_LIST",
  RESET_CHAT_THREADS: "RESET_CHAT_THREADS",
  SET_TRIGGER_UPDATE_CHAT_THREADS_LIST: "SET_TRIGGER_UPDATE_CHAT_THREADS_LIST",
  DELETE_CHAT_BY_ID: "DELETE_CHAT_BY_ID",
  SET_CLEAR_SEARCH_THREADS_LIST: "SET_CLEAR_SEARCH_THREADS_LIST",
  TRIGGER_START_NEW_CHAT: "TRIGGER_START_NEW_CHAT",
  SET_TRIGGER_SCROLL_THREADS_LIST: "SET_TRIGGER_SCROLL_THREADS_LIST",
  CREATE_NEW_THREAD_PENDING: "CREATE_NEW_THREAD_PENDING",
};
