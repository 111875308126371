import React, { useContext } from "react";
import { NerdCreateProvider } from "./NerdCreateContext";
import NerdCreateView from "./NerdCreateView";
import NerdCreateModal from "./NerdCreateModal";
import StepsMain from "./steps/StepsMain";

const NerdCreateMain = (props) => {
  return (
    <NerdCreateProvider>
      <NerdCreateModal open={props.open} onClose={props.onClose}>
        <StepsMain props={props} />
      </NerdCreateModal>
    </NerdCreateProvider>
  );
};

export default NerdCreateMain;
