import { useEffect } from "react";

import {
  sendChat, // Used for non-streaming messages.
  sendStreamChat,
  getChatMessagesByChatId,
  pushNewMessageToChatHistoryByChatId,
} from "app/services/ChatService";

import { v4 as uuidv4 } from "uuid";

import { MainChatContext } from "app/contexts/chat/MainChatContext";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";
import { useRefs } from "app/contexts/RefsContext";
import { useChatMessagesCallBacks } from "app/contexts/chat/callbacks/ChatMessagesCallBacks";

export const useChatMessagesEffects = (state, dispatch, globalState) => {
  const { currentlyProcessingMessageId, tokenTimeoutRef } = useRefs();

  const {
    removeMessageFromSyncQueue,
    replaceMessageInMessageList,
    updateMessageList,
  } = useChatMessagesCallBacks(globalState, dispatch);

  /**
   * Process Message, will send a new message ( user or nerd ) to the server, and return server Message.
   * Returned message is pushed the the messageList in place of the original message.
   */
  useEffect(() => {
    // Function to send the message and handle its response
    if (globalState?.chatInitialization?.chatInitialized) {
      const processMessage = (messageToProcess) => {
        // console.log("processSyncQueue | messageToProcess:", messageToProcess);

        // Set currentlyProcessingMessageId to the ID of the message we're starting to process
        currentlyProcessingMessageId.current =
          messageToProcess.id ||
          messageToProcess.frontEndUniqueMessageId ||
          messageToProcess._id;

        if (!currentlyProcessingMessageId.current) {
          console.error(
            "processSyncQueue | currentlyProcessingMessageId is null"
          );
          return;
        }

        // Send message to server
        // console.log("processSyncQueue | messageToProcess:", messageToProcess);

        const serverPayload = {
          chatId: globalState.chatRoom.currentChatRoom.chatId,
          chatMessage: messageToProcess,
        };

        // console.log("processSyncQueue | serverPayload:", serverPayload);

        pushNewMessageToChatHistoryByChatId(serverPayload)
          .then((data) => {
            // console.log("processSyncQueue | data:", data);
            if (
              data.status === 200 ||
              (data.status === 400 && data.body === "Message already exists")
            ) {
              if (data.status === 400) {
                console.warn(
                  "Same message sent multiple times to server, clearing the message. This can happen due to re-render prior to original message sent returning. Handled Gracefully."
                );
              }
              removeMessageFromSyncQueue(currentlyProcessingMessageId.current);
              replaceMessageInMessageList(
                messageToProcess.frontEndUniqueMessageId,
                data.data
              );
              dispatch({
                type: "SET_TRIGGER_SCROLL",
                payload: true,
              });
              currentlyProcessingMessageId.current = null;
            }
          })
          .catch((e) => {
            console.error("processSyncQueue | Error: ", e);
            return false;
          });
      };

      if (state.messageSyncQueue.length > 0) {
        // If there's a message ID in currentlyProcessingMessageId, find that message and process it

        if (currentlyProcessingMessageId.current) {
          const messageToRetry = state.messageSyncQueue.find(
            (msg) => msg.id === currentlyProcessingMessageId.current
          );

          if (messageToRetry) {
            processMessage(messageToRetry);
            return;
          }
        }

        // If no message is currently being processed, process the oldest message from the queue
        const oldestMessage = state.messageSyncQueue[0];
        processMessage(oldestMessage);
      }
    }
  }, [
    globalState.chatRoom.currentChatRoom.chatId,
    state.messageSyncQueue,
    currentlyProcessingMessageId,
    removeMessageFromSyncQueue,
    replaceMessageInMessageList,
    globalState?.chatInitialization?.chatInitialized,
    dispatch,
  ]);

  /**
   * Once Streaming is completed, sync final message to server
   *
   */
  useEffect(() => {
    if (globalState?.chatInitialization?.chatInitialized) {
      if (state.syncLastMsgToServerTrigger) {
        const tokenMessage = globalState.chatSocket.tokenStream.join("");
        dispatch({
          type: ACTION_TYPES.CLEAR_STREAM_ARRAY,
        });

        // Reset syncLastMsgToServerTrigger to false
        dispatch({ type: "SET_SYNC_STREAM_MESSAGE", payload: false });

        // Add message to sync queue
        // addMessageToSyncQueue(latestMessage);
        // Update messageList - this will re-add the message as a normal text not object of type Token.
        updateMessageList(tokenMessage, "nerdMessage");
      }
    }
  }, [
    dispatch,
    globalState?.chatInitialization?.chatInitialized,
    state.messageList,
    state.messageSyncQueue,
    state.syncLastMsgToServerTrigger,
    updateMessageList,
  ]);
};
