import axios from "axios";

const instance = axios.create();

export const setupAxios = (setup, nodejsConfig, settings, updateSettings) => {
  if (setup.environment === "development") {
    instance.defaults.baseURL = nodejsConfig.backendApiFull;
  } else {
    instance.defaults.baseURL = "https://api.example.com";
  }

  const { baseURL } = instance.defaults; // Defines a constant with BaseUrl for matching in interceptor rules below.

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lies within the range of 2xx will cause this function to trigger
      return response;
    },
    (error) => {
      // Any status codes that fall outside the range of 2xx will cause this function to trigger

      console.log(
        `>>> setupAxios | error.config.baseURL.startsWith(baseURL) [baseUrl -> ${baseURL} ] >>> ${
          error.config.baseURL.startsWith(baseURL) ? "TRUE" : "FALSE"
        }`
      );

      console.log(">>> setupAxios | error.response >>> ", error.response);
      console.log(
        ">>> setupAxios | error.response.status >>> ",
        error.response.status
      );
      console.log(
        ">>> setupAxios | settings.isAxiosSetup >>> ",
        settings.isAxiosSetup
      );

      if (
        error.response &&
        error.response.status === 401 &&
        settings.isAxiosSetup && // Axios is setup & ready
        error.config.baseURL.startsWith(baseURL)
      ) {
        // If the response status is 401 and the request was made to the default server
        // Trigger logout here

        // Will attempt to refresh the access token on the nodejs server, using the refresh token.
        // If successful, it will update the access token in the nodejs server and return the new access token.
        // If fails, it will logout the user.

        console.log(`>>> setupAxios | settings: `);
        console.log(settings);

        if (!settings.flags.server.triggerRefreshToken) {
          console.log(
            ">>> setupAxios | Unauthorized request to default server, refreshing tokens...."
          );

          updateSettings({
            flags: {
              ...settings.flags,
              server: {
                ...settings.flags.server,
                triggerRefreshToken: true,
                refreshResult: null,
              },
            },
            showGlobalAlert: {
              show: true,
              message: "Refreshing tokens... Page will reload in 3 seconds.",
              severity: "info",
            },
          });

          setTimeout(() => {
            // window.location.reload(); // Reload the page to trigger the refresh token flow.
            console.warn("EDDIE DON'T FORGET TO RETURN THIS LINE!!!");
          }, 3000);
        }

        console.error(">>> Unauthorized request to default server!.");
      }
      return Promise.reject(error);
    }
  );
};

export default instance;
