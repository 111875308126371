import { createContext, useReducer, useContext, useEffect } from "react";
import { hireNerd } from "app/services/nerdServices";
import { toast } from "react-toastify";
import { MainNerdsContext } from "app/contexts/nerds/MainNerdsContext";
import { useMemo } from "react";
import {
  maleAvatar,
  femaleAvatar,
  botAvatar,
  diversityAvatar,
} from "app/constants/base64Images/Avatars";
import { StableDiffusionContext } from "app/contexts/stableDiffusion/StableDiffusionContext";

const NerdCreateContext = createContext();

const initialState = {
  formVisible: false,
  avatarURL: null, // Default avatar URL
  defaultAvatarURLs: {
    male: maleAvatar,
    female: femaleAvatar,
    bot: botAvatar,
    diversity: diversityAvatar,
  },
  selectedGender: "male", // Default gender
  useUploader: false,
  formErrors: {},
  formValid: false,
  formPristine: true,
  formValues: {
    name: "",
    diversity: "",
    diversityUserDescription: "",
    avatar: "", // Base64 encoded image
  },
  submitState: "idle",
  stepperStep: 0,
  stepsValidation: {
    0: {
      isValid: true,
    },
    1: {
      isValid: true,
    },
    2: {
      isValid: true,
    },
  },
  avatarThemeSelect: "",
  avatarThemeSelectOptions: [],
  suggestedNames: [],
  nameSuggestionLimit: 3,
  nameSuggestionCounter: 0,
  nationalityChoise: "USA",
  avatarAge: 20,
};

const reducer = (state, action) => {
  console.log("action:", action);
  console.log("state:", state);
  switch (action.type) {
    case "SET_AVATAR_URL":
      return { ...state, avatarURL: action.payload };
    case "SET_SELECTED_GENDER":
      return { ...state, selectedGender: action.payload, suggestedNames: [] };
    case "SET_USE_UPLOADER":
      return { ...state, useUploader: action.payload };
    case "SET_FORM_ERRORS":
      return { ...state, formErrors: action.payload };
    case "SET_FORM_VALUES":
      return { ...state, formValues: action.payload, suggestedNames: [] };
    case "SET_FORM_DIRTY":
      return { ...state, formPristine: false };
    case "SET_SUBMIT_STATE":
      return { ...state, submitState: action.payload };
    case "SET_FORM_VISIBILE":
      return { ...state, formVisible: action.payload };
    case "SET_FORM_VALID":
      return { ...state, formValid: action.payload };
    case "SET_STEPPER_STEP":
      return { ...state, stepperStep: action.payload };
    case "SET_AVATAR_THEME_SELECT":
      return { ...state, avatarThemeSelect: action.payload || "" };
    case "SET_STEPS_VALIDATION":
      return {
        ...state,
        stepsValidation: {
          ...state.stepsValidation,
          [action.payload.step]: {
            isValid: action.payload.isValid,
          },
        },
      };
    case "SET_SUGGESTED_NAMES":
      return { ...state, suggestedNames: action.payload };
    case "RESET":
      return initialState;
    case "SET_AVATAR_THEME_OPTIONS":
      return { ...state, avatarThemeSelectOptions: action.payload };
    case "SET_NAME_SUGGESTION_COUNTER":
      return { ...state, nameSuggestionCounter: action.payload };
    case "SET_NATIONALITY_SELECT":
      return { ...state, nationalityChoise: action.payload };
    case "SET_AVATAR_AGE":
      return { ...state, avatarAge: action.payload };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const NerdCreateProvider = ({ children }) => {
  const { getNerds, onModalClose } = useContext(MainNerdsContext);
  const { models, getModelsAction } = useContext(StableDiffusionContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const setFormDirty = () => dispatch({ type: "SET_FORM_DIRTY" });

  const HireNerdAction = (payload) => {
    hireNerd(payload)
      .then((payload) => {
        toast.success("Nerd Hired Successfully");
        getNerds();
        onModalClose(false);
        dispatch({ type: "SET_SUBMIT_STATE", payload: "success" });
      })
      .catch((err) => {
        console.error("HireNerdAction error:", err);
        toast.error(
          "Error hiring nerd: " + err?.response?.data?.message || err?.message
        );
        dispatch({ type: "SET_SUBMIT_STATE", payload: "error" });
      });
  };

  const handleSubmit = () => {
    if (state.formPristine) {
      setFormDirty();
    }

    // console.log("handleSubmit | state:", state);

    if (state.formValid) {
      // Proceed with form submission logic

      HireNerdAction({
        nerd: {
          name: state.formValues.name,
          avatarUrl: getAvatarUrl(),
          // traits: state.formValues.traits,
        },
      });
    }
  };

  const getAvatarImage = () => {
    return (
      state?.defaultAvatarURLs?.[state?.selectedGender] ||
      "/assets/images/avatars/default_bot.svg"
    );
  };

  const getAvatarUrl = () => {
    // const defaultAvatar = "https://" + window.location.host + getAvatarImage(); // Locally hosted image
    const defaultAvatar = getAvatarImage(); // Locally hosted image
    // TODO: Use a CDN for the default avatar images, don't store them locally, and use versions as they might change over time.
    const avatarUrl = state.avatarURL || defaultAvatar; // Use uploaded image if available, else use default avatar
    return avatarUrl;
  };

  const setStepperStep = (step) => {
    dispatch({ type: "SET_STEPPER_STEP", payload: step });
  };

  const getAvatarThemeSelectOptions = useMemo(() => {
    console.log(
      "getAvatarThemeSelectOptions | avatarThemeSelectOptions:",
      state.avatarThemeSelectOptions
    );
    return state.avatarThemeSelectOptions;
  }, [state.avatarThemeSelectOptions]);

  const setStepsValidation = (step, isValid) => {
    dispatch({
      type: "SET_STEPS_VALIDATION",
      payload: { step, isValid },
    });
  };

  const setSuggestedNames = (names) => {
    dispatch({ type: "SET_SUGGESTED_NAMES", payload: names });
  };

  useEffect(() => {
    if (!models) {
      getModelsAction();
    } else {
      if (models.length > 0) {
        const mappedModels = models.map((model) => {
          return {
            value: model.name,
            label: model.displayName,
          };
        });
        if (
          JSON.stringify(state.avatarThemeSelectOptions) !==
          JSON.stringify(mappedModels)
        ) {
          dispatch({ type: "SET_AVATAR_THEME_OPTIONS", payload: mappedModels });
        }
      }
    }
  }, [getModelsAction, models, state.avatarThemeSelectOptions]);

  const updateNameSuggestionCounter = () => {
    dispatch({
      type: "SET_NAME_SUGGESTION_COUNTER",
      payload: state.nameSuggestionCounter + 1,
    });
  };

  return (
    <NerdCreateContext.Provider
      value={{
        ...state,
        avatarURL: state.avatarURL,
        setAvatarURL: (url) =>
          dispatch({ type: "SET_AVATAR_URL", payload: url }),
        selectedGender: state.selectedGender,
        setSelectedGender: (gender) =>
          dispatch({ type: "SET_SELECTED_GENDER", payload: gender }),
        useUploader: state.useUploader,
        setUseUploader: (value) =>
          dispatch({ type: "SET_USE_UPLOADER", payload: value }),
        avatarTheme: state.avatarTheme,
        formErrors: state.formErrors,
        setFormErrors: (errors) =>
          dispatch({ type: "SET_FORM_ERRORS", payload: errors }),
        formValues: state.formValues,
        setFormValues: (values) =>
          dispatch({ type: "SET_FORM_VALUES", payload: values }),
        resetForm: () => dispatch({ type: "RESET" }),
        setFormDirty,
        handleSubmit,
        setFormVisible: (value) =>
          dispatch({ type: "SET_FORM_VISIBILE", payload: value }),
        setFormValid: (value) =>
          dispatch({ type: "SET_FORM_VALID", payload: value }),
        getAvatarImage,
        getAvatarUrl,
        setStepperStep,
        setAvatarThemeSelect: (value) =>
          dispatch({ type: "SET_AVATAR_THEME_SELECT", payload: value }),
        setNationality: (value) =>
          dispatch({ type: "SET_NATIONALITY_SELECT", payload: value }),
        getAvatarThemeSelectOptions,
        setStepsValidation,
        setSuggestedNames,
        updateNameSuggestionCounter,
        updateAvatarAge: (value) =>
          dispatch({ type: "SET_AVATAR_AGE", payload: value }),
        reset: () => {
          setTimeout(() => {
            dispatch({ type: "RESET" });
          }, 200);
        },
      }}
    >
      {children}
    </NerdCreateContext.Provider>
  );
};

export { NerdCreateProvider, NerdCreateContext };
