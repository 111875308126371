import { useCallback, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import {
  startNewChat,
  sendChat, // Used for non-streaming messages.
  getThreadList,
  deleteChatById,
} from "app/services/ChatService";

import { get } from "lodash";
import memoize from "lodash.memoize";

import { MainChatContext } from "app/contexts/chat/MainChatContext";

import { ACTION_TYPES } from "app/contexts/chat/actions/ActionTypes";

import { useChatThreadsCallBacks } from "app/contexts/chat/callbacks/ChatThreadsCallBacks";

export const useChatThreadsEffects = (state, dispatch, globalState) => {
  const {
    clearSearchThreadsListHandler,
    startNewChatThreadHandler,
    findNewThread,
  } = useChatThreadsCallBacks(globalState, dispatch);

  /**
   * Filter messageList based on searchThreadsList
   */
  useEffect(() => {
    if (globalState?.chatInitialization?.chatInitialized) {
      // If not filtered, return the original messageList
      if (!state.searchThreadsList || state.searchThreadsList.length === 0) {
        if (state.threadsList.length === 0) {
          // No threads found, and no search query
          return;
        }

        const sortedThreadsList = state.threadsList.sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });

        if (
          JSON.stringify(state.filteredThreadsList) !==
          JSON.stringify(sortedThreadsList)
        ) {
          dispatch({
            type: "SET_FILTERED_THREADS_LIST",
            payload: sortedThreadsList,
          });
        }
        return;
      } else if (
        state.searchThreadsList &&
        state.searchThreadsList.length > 0
      ) {
        const filteredThreadsList = state.threadsList.filter((thread) => {
          const chatName = get(thread, "chatName", "");
          const chatNameLowerCase = chatName.toLowerCase();
          const searchThreadsListLowerCase =
            state.searchThreadsList.toLowerCase();
          const meta_description = get(thread, "meta_description", "");
          const meta_descriptionLowerCase = meta_description.toLowerCase();
          return (
            chatNameLowerCase.includes(searchThreadsListLowerCase) ||
            meta_descriptionLowerCase.includes(searchThreadsListLowerCase)
          );
        });

        const filteredAndSortedThreadsList = filteredThreadsList.sort(
          (a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          }
        );

        if (
          JSON.stringify(state.filteredThreadsList) !==
          JSON.stringify(filteredAndSortedThreadsList)
        ) {
          dispatch({
            type: "SET_FILTERED_THREADS_LIST",
            payload: filteredAndSortedThreadsList,
          });
        }

        return;
      }
    }
  }, [
    state.searchThreadsList,
    state.filteredThreadsList,
    state.messageList,
    state.threadsList,
    dispatch,
    clearSearchThreadsListHandler,
    globalState?.chatInitialization?.chatInitialized,
  ]);

  /**
   * Trigger startNewChatThreadHandler based on globalState change
   */
  useEffect(() => {
    if (state.triggerStartNewChat) {
      dispatch({
        type: ACTION_TYPES.TRIGGER_START_NEW_CHAT,
        payload: false,
      });
      const newThread = findNewThread();
      if (newThread) {
        return; // Only start a new thread if there isn't one availabel already.
      }

      // To trigger a new chat, simply reset the globalState.chatInitialization
      /* dispatch({
        type: ACTION_TYPES.CHAT_INIT_START_NEW_CHAT,
      }); */

      startNewChatThreadHandler();
    }
  }, [
    state.triggerStartNewChat,
    dispatch,
    startNewChatThreadHandler,
    findNewThread,
  ]);
};
