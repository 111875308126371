import { CHAT_ROOM_ACTIONS } from "./chatRoomActions";
import { CHAT_THREADS_ACTIONS } from "./chatThreadsActions";
import { CHAT_MESSAGES_ACTIONS } from "./chatMessagesActions";
import { CHAT_SOCKET_ACTIONS } from "./chatSocketActions";
import { CHAT_PARTICIPANTS_ACTIONS } from "./chatParticipantsActions";
import { CHAT_INIT_ACTIONS } from "./chatInitActions";

export const ACTION_TYPES = {
  // GLOBAL ACTIONS
  RESET_ALL: "RESET_ALL",

  ...CHAT_ROOM_ACTIONS,
  ...CHAT_THREADS_ACTIONS,
  ...CHAT_MESSAGES_ACTIONS,
  ...CHAT_SOCKET_ACTIONS,
  ...CHAT_PARTICIPANTS_ACTIONS,
  ...CHAT_INIT_ACTIONS,
};
